<template>
    <div :class="`grid-gallery grid-gallery--variant-${variant}`">
        <img
            :src="url"
            class="grid-gallery__img"
            :class="`grid-gallery--variant-${variant}__img`"
            v-for="(url, i) in imgsUrls"
            :key="i"
        />
    </div>
</template>
<script>
export default {
    props: {
        variant: String,
        imgsUrls: Array,
    },
};
</script>
