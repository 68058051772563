<template>
    <Head>
        <title>
            {{
                $t('TITLES.ship-detail', { shipName: details.name?.[LANGUAGE] })
            }}
        </title>
        <meta
            name="description"
            :content="
                $t('META-DESCRIPTIONS.ship-detail', {
                    shipName: details.name?.[LANGUAGE],
                })
            "
        />
    </Head>

    <header class="header-search">
        <div class="header-search__background header-search__background">
            <video
                class="header-search__background-video"
                ref="video"
                :src="details.heroVideo"
                poster="/assets/imgs/cruiser-list.jpg"
                muted
                autoplay
                loop
            ></video>
        </div>
        <Main-nav type="cruiser"></Main-nav>
        <div class="cruiser-page__hero-dash">
            <h1 class="cruiser-page__heading-1">
                {{ details.name?.[LANGUAGE] }}
            </h1>
            <p class="cruiser-page__sub-heading-1">
                {{ details.subtitle?.[LANGUAGE] }}
            </p>
        </div>
    </header>
    <main class="main">
        <section class="section">
            <div class="section__inner">
                <div class="cruiser-page__information">
                    <Swiper
                        :slides-per-view="1"
                        class="
                            cabin-showcase__slider
                            cruiser-page__information-slider
                        "
                        :autoplay="{
                            delay: 3000,
                        }"
                        @swiper="saveInfoSwiper"
                    >
                        <button
                            class="
                                cabin-showcase__slider-btn
                                cabin-showcase__slider-btn--prev
                            "
                            :class="{
                                'cabin-showcase__slider-btn--disabled':
                                    infoSwiper?.isBeginning,
                                'cabin-showcase__slider-btn--hidden':
                                    infoSwiper?.isBeginning &&
                                    infoSwiper?.isEnd,
                            }"
                            @click="prevSlide(infoSwiper)"
                        >
                            <svg
                                class="
                                    cabin-showcase__slider-icon
                                    cabin-showcase__slider-icon--left
                                "
                            >
                                <use
                                    href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                ></use>
                            </svg>
                        </button>
                        <Swiper-slide v-for="(imgUrl, i) in infoImages" :key="i"
                            ><img :src="imgUrl" class="cabin-showcase__slide"
                        /></Swiper-slide>
                        <button
                            class="
                                cabin-showcase__slider-btn
                                cabin-showcase__slider-btn--next
                            "
                            :class="{
                                'cabin-showcase__slider-btn--disabled':
                                    infoSwiper?.isEnd,
                                'cabin-showcase__slider-btn--hidden':
                                    infoSwiper?.isEnd &&
                                    infoSwiper?.isBeginning,
                            }"
                            @click="nextSlide(infoSwiper)"
                        >
                            <svg
                                class="
                                    cabin-showcase__slider-icon
                                    cabin-showcase__slider-icon--right
                                "
                            >
                                <use
                                    href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                ></use>
                            </svg>
                        </button>
                    </Swiper>
                    <div class="cruiser-page__information-box">
                        <h2 class="cruiser-page__information-heading">
                            {{ $t('LABELS.basic-ship-info') }}
                        </h2>
                        <div
                            class="cruiser-page__information-row"
                            v-if="details.param_cabin_count"
                        >
                            <p class="cruiser-page__information-label">
                                {{ $t('LABELS.cabins-number') }}
                            </p>
                            <p class="cruiser-page__information-value">
                                {{ details.param_cabin_count }}
                            </p>
                        </div>
                        <div
                            class="cruiser-page__information-row"
                            v-if="details.param_length"
                        >
                            <p class="cruiser-page__information-label">
                                {{ $t('LABELS.ship-length') }}
                            </p>
                            <p class="cruiser-page__information-value">
                                {{ details.param_length }}m
                            </p>
                        </div>
                        <div
                            class="cruiser-page__information-row"
                            v-if="details.param_width"
                        >
                            <p class="cruiser-page__information-label">
                                {{ $t('LABELS.ship-width') }}
                            </p>
                            <p class="cruiser-page__information-value">
                                {{ details.param_width }}m
                            </p>
                        </div>
                        <div
                            class="cruiser-page__information-row"
                            v-if="details.param_passenger_count"
                        >
                            <p class="cruiser-page__information-label">
                                {{ $t('LABELS.passanger-count') }}
                            </p>
                            <p class="cruiser-page__information-value">
                                {{ details.param_passenger_count }}
                            </p>
                        </div>
                        <div
                            class="cruiser-page__information-row"
                            v-if="details.param_speed"
                        >
                            <p class="cruiser-page__information-label">
                                {{ $t('LABELS.speed-in-knots') }}
                            </p>
                            <p class="cruiser-page__information-value">
                                {{ details.param_speed }}
                            </p>
                        </div>
                        <div
                            class="cruiser-page__information-row"
                            v-if="details.param_tonne"
                        >
                            <p class="cruiser-page__information-label">
                                {{ $t('LABELS.weight') }}
                            </p>
                            <p class="cruiser-page__information-value">
                                {{ details.param_tonne }}t
                            </p>
                        </div>
                    </div>
                    <div
                        class="
                            cruiser-page__information-texts
                            cruiser-page__wysiwyg
                        "
                        v-html="details.text_one?.[LANGUAGE]"
                    ></div>
                </div>
            </div>
        </section>
        <section class="section section--light-blue" v-if="this.cabin">
            <div class="section__inner">
                <div class="cruiser-page__cabins-container">
                    <div class="cruiser-page__cabins-list">
                        <h2 class="cruiser-page__cabins-heading">
                            {{ $t('LABELS.cabins') }}
                        </h2>
                        <div
                            class="cruiser-page__cabin-radio-container"
                            v-for="shipCabin in details.cabins"
                            :key="shipCabin.id"
                        >
                            <input
                                type="radio"
                                name="cabin"
                                :id="shipCabin.id"
                                :value="shipCabin.id"
                                class="cruiser-page__cabin-radio"
                                v-model="cabin"
                            />
                            <label
                                :for="shipCabin.id"
                                class="cruiser-page__cabin-label"
                                >{{ shipCabin.name?.[LANGUAGE] }}</label
                            >
                        </div>
                    </div>
                    <div class="cruiser-page__cabins-slider-container">
                        <Swiper
                            :slides-per-view="1"
                            class="
                                cabin-showcase__slider
                                cruiser-page__cabin-slider
                            "
                            @swiper="saveCabinsSwiper"
                            :autoplay="{
                                delay: 3000,
                            }"
                        >
                            <button
                                class="
                                    cabin-showcase__slider-btn
                                    cabin-showcase__slider-btn--prev
                                "
                                :class="{
                                    'cabin-showcase__slider-btn--disabled':
                                        cabinsSwiper?.isBeginning,
                                    'cabin-showcase__slider-btn--hidden':
                                        cabinsSwiper?.isBeginning &&
                                        cabinsSwiper?.isEnd,
                                }"
                                @click="prevSlide(cabinsSwiper)"
                            >
                                <svg
                                    class="
                                        cabin-showcase__slider-icon
                                        cabin-showcase__slider-icon--left
                                    "
                                >
                                    <use
                                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                    ></use>
                                </svg>
                            </button>
                            <Swiper-slide
                                v-for="(imgUrl, i) in cabinImages[cabin]"
                                :key="i"
                                ><img
                                    :src="imgUrl"
                                    class="cabin-showcase__slide"
                            /></Swiper-slide>
                            <button
                                class="
                                    cabin-showcase__slider-btn
                                    cabin-showcase__slider-btn--next
                                "
                                :class="{
                                    'cabin-showcase__slider-btn--disabled':
                                        cabinsSwiper?.isEnd,
                                    'cabin-showcase__slider-btn--hidden':
                                        cabinsSwiper?.isEnd &&
                                        cabinsSwiper?.isBeginning,
                                }"
                                @click="nextSlide(cabinsSwiper)"
                            >
                                <svg
                                    class="
                                        cabin-showcase__slider-icon
                                        cabin-showcase__slider-icon--right
                                    "
                                >
                                    <use
                                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                                    ></use>
                                </svg>
                            </button>
                        </Swiper>
                        <p
                            class="cruiser-page__cabin-slider-label"
                            v-if="cabinTexts[cabin]"
                        >
                            {{ cabinTexts[cabin] }}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="section__inner">
                <div
                    class="cruiser-page__wysiwyg"
                    v-html="details.text_two?.[LANGUAGE]"
                ></div>
                <router-link
                    :to="`/vyhledat/#shipCode=${details.code}`"
                    class="
                        btn btn--blue btn--standalone-large
                        cruiser-page__search-btn
                    "
                    >Zobrazit zájezdy na <wbr />{{
                        details.name?.[LANGUAGE]
                    }}</router-link
                >
            </div>
        </section>
    </main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :label="$t('STATUSES.loading-page')"
    ></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

import { Head } from '@vueuse/head';

import { LANGUAGE, TYPE } from '@/env';

export default {
    props: ['shipCode'],
    components: {
        Footer,
        MainNav,
        Loading,
        Swiper,
        SwiperSlide,
        Head,
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            isLoading: false,
            infoSwiper: null,
            cabinsSwiper: null,
            infoImages: [
                '/assets/imgs/banners/banner-vysledky-vyhledavani-cruiser-yacht-desk-270x320.png',
                '/assets/imgs/banners/banner-vysledky-vyhledavani-cruiser-yacht-desk-270x320.png',
            ],
            cabin: 'cabin-1',
            cabinImages: {
                'cabin-1': [
                    '/assets/imgs/banners/banner-vysledky-vyhledavani-cruiser-yacht-desk-270x320.png',
                    '/assets/imgs/banners/banner-vysledky-vyhledavani-cruiser-yacht-desk-270x320.png',
                ],
                'cabin-2': [
                    '/assets/imgs/cruiser-detail-background.jpg',
                    '/assets/imgs/flights.jpg',
                ],
                'cabin-3': [
                    '/assets/imgs/yacht-detail-background-2.jpg',
                    '/assets/imgs/yacht-detail-background.jpg',
                ],
            },
            cabinTexts: {
                'cabin-1': 'Text ke kajutě 1',
                'cabin-2': null,
                'cabin-3': 'Text ke kajutě 3',
            },
            details: {},
        };
    },
    computed: {},
    methods: {
        saveInfoSwiper(swiper) {
            this.infoSwiper = swiper;
        },
        saveCabinsSwiper(swiper) {
            this.cabinsSwiper = swiper;
        },
        nextSlide(swiper) {
            swiper.slideNext();
        },
        prevSlide(swiper) {
            swiper.slidePrev();
        },
        async loadDetails() {
            const shipDetail = await this.api.getShipDetail(this.shipCode);

            console.log(shipDetail);

            this.details = shipDetail;
            this.infoImages = shipDetail.galleryImages;
            this.cabin = shipDetail.cabins[0]?.id;

            shipDetail.cabins.forEach((cabin) => {
                this.cabinImages[cabin.id] = cabin.cabinImages;
            });

            shipDetail.cabins.forEach((cabin) => {
                this.cabinTexts[cabin.id] = cabin.description[LANGUAGE];
            });

            if (!shipDetail) {
                this.$router.push(`/`);
                return;
            }
        },
    },
    async mounted() {
        this.isLoading = true;
        await this.loadDetails();
        this.isLoading = false;
    },
    watch: {},
};
</script>
