<template>
    <Head>
        <title>{{ $t('TITLES.homepage') }}</title>
    </Head>
    <header class="header-detail" ref="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main main--cruises-delegate cruises-delegate-order-form">
        <div class="cruises-delegate-order-form__inner">
            <div class="cruises-delegate-order-form__content">
                <form
                    class="cruises-delegate-order-form__column"
                    @submit.prevent="sendOrderForm"
                >
                    <h2 class="cruises-delegate-order-form__heading">
                        {{ cruiseName }}
                    </h2>
                    <Notification
                        :text="error.text[LANGUAGE]"
                        v-if="error.show"
                        :initConfig="{ small: true }"
                        @closed="closeNotification"
                    ></Notification>
                    <h3 class="person-form__heading">
                        {{ $t('LABELS.contact-form') }}
                    </h3>
                    <div class="cruises-delegate-order-form__order-form">
                        <div
                            class="cruises-delegate-order-form__order-form__inputs"
                        >
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="first-name"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.first-name') }}</label
                                >
                                <input
                                    type="text"
                                    id="first-name"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model="firstName"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="last-name"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.last-name') }}</label
                                >
                                <input
                                    type="text"
                                    id="last-name"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model="lastName"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="email"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.email') }}</label
                                >
                                <input
                                    type="email"
                                    id="email"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model="email"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="phone"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.phone-number') }}</label
                                >
                                <input
                                    type="tel"
                                    id="phone"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model="phone"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="adults-number"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.adults-with-age') }}</label
                                >
                                <input
                                    type="number"
                                    :min="1"
                                    :max="99"
                                    id="adults-number"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model.number="adultsNumber"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="children-number"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.children-with-age') }}</label
                                >
                                <input
                                    type="number"
                                    :min="0"
                                    :max="99"
                                    id="children-number"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model.number="childrenNumber"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="cabins-number"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.cabins-number') }}</label
                                >
                                <input
                                    type="number"
                                    :min="0"
                                    :max="99"
                                    id="cabins-number"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model.number="cabinsNumber"
                                    required
                                />
                            </div>
                            <div
                                class="cruises-delegate-order-form__order-form__input-wrapper"
                            >
                                <label
                                    for="cclub-number"
                                    class="cruises-delegate-order-form__order-form__label"
                                    >{{ $t('LABELS.cclub-number') }}</label
                                >
                                <input
                                    type="text"
                                    id="cclub-number"
                                    class="cruises-delegate-order-form__order-form__input"
                                    v-model="cclubNumber"
                                />
                            </div>
                        </div>

                        <div
                            class="cruises-delegate-order-form__order-form__input-wrapper"
                        >
                            <label
                                for="message"
                                class="cruises-delegate-order-form__order-form__label-message"
                                >{{ $t('LABELS.message') }}</label
                            >
                            <textarea
                                id="message"
                                class="cruises-delegate-order-form__order-form__textarea"
                                v-model="message"
                            ></textarea>
                        </div>

                        <div class="checkbox-agree">
                            <input
                                type="checkbox"
                                class="checkbox-agree__checkbox"
                                id="gdpr-agree"
                                v-model="allowGDPR"
                            />
                            <label
                                for="gdpr-agree"
                                class="checkbox-agree__label"
                                >{{ $t('TEXTS.agree-with-gdpr') }}
                                <a
                                    href="https://info.costa-cruises.cz/ochrana-soukromi/"
                                    class="checkbox-agree__link"
                                    target="_blank"
                                    >{{ $t('TEXTS.GDPR') }}</a
                                ></label
                            >
                        </div>

                        <input
                            type="submit"
                            value="Odeslat"
                            class="cruises-delegate-order-form__order-form__submit-btn btn btn--full-width btn--orange"
                        />
                    </div>
                </form>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :label="$t('STATUSES.loading-cruise-info')"
    ></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import { Api } from '../../modules/Api';
import Notification from '../../components/Notification.vue';
import Loading from '../../components/Loading.vue';

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';

export default {
    props: ['cruise_code'],
    components: {
        Footer,
        MainNav,
        Notification,
        Loading,
        Head,
    },
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            details: {},
            cruiseName: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            adultsNumber: 1,
            childrenNumber: 0,
            cabinsNumber: 1,
            cclubNumber: '',
            message: '',
            allowGDPR: false,
            error: {
                show: false,
                text: [],
            },
        };
    },
    methods: {
        async sendOrderForm() {
            this.closeNotification();

            if (
                this.firstName === '' ||
                this.lastName === '' ||
                this.email === '' ||
                this.phone === '' ||
                this.adultsNumber === '' ||
                this.childrenNumber === '' ||
                this.cabinsNumber === ''
            ) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.fill-required')],
                    sk: [this.$t('ERRORS.fill-required')],
                };
                window.scrollTo(0, 0);
                return false;
            }

            if (this.phone.split(' ').length > 1 || this.phone.length != 9) {
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.wrong-phone-format')],
                    sk: [this.$t('ERRORS.wrong-phone-format')],
                };
                window.scrollTo(0, 0);
                return false;
            }

            if (!this.allowGDPR) {
                window.scrollTo(0, 0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.agree-gdpr')],
                    sk: [this.$t('ERRORS.agree-gdpr')],
                };
                window.scrollTo(0, 0);
                return false;
            }

            this.isLoading = true;

            const reqBody = {
                cruiseName: this.cruiseName,
                cruiseCode: this.details?.code,
                firstName: this.firstName,
                lastName: this.lastName,
                email: this.email,
                phone: this.phone,
                adultsNumber: this.adultsNumber,
                childrenNumber: this.childrenNumber,
                cabinsNumber: this.cabinsNumber,
                cclubNumber: this.cclubNumber,
                message: this.message,
            };
            const resp = await this.api.sendCruisesDelegateOrderForm(reqBody);
            this.isLoading = false;

            if (!resp.data.send) {
                window.scrollTo(0, 0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.contact-form-send-fail')],
                    sk: [this.$t('ERRORS.contact-form-send-fail')],
                };
                window.scrollTo(0, 0);
                return false;
            }

            this.$router.push('/plavba-s-delegatem-odeslano/');
        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: [],
            };
        },
    },
    async mounted() {
        this.isLoading = true;

        this.details = {
            ...this.details,
            ...(await this.api.getCruisesDelegateDetail(this.cruise_code)),
        };

        if (!this.details?.code) {
            this.$router.push(
                `/plavba-s-delegatem/${this.cruise_code}/nenalezeno/`
            );
        }

        this.cruiseName = this.details.name[LANGUAGE];

        this.isLoading = false;
    },
    computed: {},
    watch: {},
};
</script>
