<template>
    <Head>
        <title>{{ $t('TITLES.homepage') }}</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main reservation reservation--done">
        <div class="reservation__inner">
            <div class="popup popup--unpoped">
                <h1 class="popup__heading popup__heading--yacht">
                    {{ $t('TEXTS.order-completed') }}
                </h1>
                <p class="popup__text">
                    {{ $t('TEXTS.order-completed-full-part-1') }}
                    <span v-if="!redirectUrl">{{
                        $t('TEXTS.order-completed-full-part-2')
                    }}</span>
                </p>
                <p class="popup__text">
                    <strong class="popup__strong popup__strong--yacht">
                        {{
                            redirectUrl
                                ? $t('TEXTS.redirect-to-payment-gateway')
                                : $t('TEXTS.order-completed-part-4-5-day')
                        }}
                    </strong>
                </p>
                <p class="popup__text">
                    {{ $t('TEXTS.order-completed-part-5') }}
                    <router-link
                        to="/uzivatel/"
                        class="popup__link popup__link--yacht"
                    >
                        {{ $t('TEXTS.order-completed-part-6') }}
                    </router-link>
                    {{ $t('TEXTS.order-completed-part-7') }}
                </p>
                <router-link
                    to="/vyhledat/"
                    class="popup__btn btn btn--orange btn--standalone"
                    v-if="!redirectUrl"
                >
                    {{ $t('LABELS.to-cruise-search') }}
                </router-link>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :label="$t('STATUSES.loading-trip-info')"
    ></Loading>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import { Api } from '../../modules/Api';
import Loading from '../../components/Loading.vue';

import { Head } from '@vueuse/head';

export default {
    components: {
        Footer,
        MainNav,
        Loading,
        Head,
    },
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
        };
    },
    computed: {
        redirectUrl() {
            const { searchParams } = new URL(window.location.href);
            return searchParams.get('redirect');
        },
    },
    methods: {
        async redirectToCardPayment() {
            setInterval(() => {
                window.location.href = this.redirectUrl;
            }, 3000);
        },
    },
    async mounted() {
        if (this.redirectUrl) {
            return this.redirectToCardPayment();
        }
    },
};
</script>
