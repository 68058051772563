<template>
    <Head>
        <title>
            {{
                $t('TITLES.content-page', {
                    pageName: $t('LABELS.how-it-works'),
                })
            }}
        </title>
        <meta
            name="description"
            :content="
                $t('META-DESCRIPTIONS.content-page', {
                    pageName: $t('LABELS.how-it-works'),
                })
            "
        />
    </Head>
    <header
        :style="{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }"
    >
        <Main-nav></Main-nav>
        <Content-hero
            :style="{ flex: 1 }"
            background-img-url="/assets/imgs/content-headers/for-firms.jpg"
            :title="$t('LABELS.how-it-works')"
            :sub-title="$t('LABELS.info-about-costa-cruises')"
        />
    </header>
    <main class="main content-pages__main">
        <!-- CONTENT START -->
        <div class="content-pages__how-it-works">
            <h2 class="content-pages__how-it-works__title">
                {{ $t('TEXTS.how-and-why-costa') }}
            </h2>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>{{ $t('TEXTS.how-it-works-subtitle-1') }}</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    {{ $t('TEXTS.how-it-works-text-1') }}
                </p>
            </section>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>{{ $t('TEXTS.how-it-works-subtitle-2') }}</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    {{ $t('TEXTS.how-it-works-text-2') }}
                </p>
            </section>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>{{ $t('TEXTS.how-it-works-subtitle-3') }}</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    {{ $t('TEXTS.how-it-works-text-3') }}
                </p>
            </section>
            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>{{ $t('TEXTS.how-it-works-subtitle-4') }}</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    {{ $t('TEXTS.how-it-works-text-4') }}
                </p>
            </section>
        </div>
        <!-- CONTENT END -->
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head';
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContactBar from '../../components/ContactBar';
import Footer from '../../components/Footer';
import { TYPE, LANGUAGE } from '@/env';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        Footer,
        ContactBar,
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
        };
    },
    methods: {},
    mounted() {},
};
</script>
