<template>
    <div class="cabin-delegate">
        <div class="cabin-delegate__type-select">
            <v-select
                :options="prices"
                :reduce="(price) => prices.indexOf(price)"
                v-model="selectedPriceIndex"
                :clearable="false"
            >
                <template #option="option">
                    {{
                        option.description[LANGUAGE].length > 0
                            ? option.description[LANGUAGE]
                            : `${option.code} - ${option.fare[LANGUAGE]}`
                    }}
                </template>
                <template #selected-option="option">
                    {{
                        option.description[LANGUAGE].length > 0
                            ? option.description[LANGUAGE]
                            : `${option.code} - ${option.fare[LANGUAGE]}`
                    }}
                </template>
            </v-select>
        </div>
        <button
            class="btn btn--blue btn--block cabin-delegate__remove-btn"
            @click="emitCabinRemove()"
        >
            - {{ $t('LABELS.remove-cabin') }}
        </button>

        <div>
            <p class="cabin-delegate__text">
                {{ $t('LABELS.adults-with-age') }}
            </p>
            <Counter
                :min="0"
                :max="maxAdults"
                :initial="initAdults"
                @changed="setAdultsNum"
            ></Counter>
        </div>
        <div>
            <p class="cabin-delegate__text">
                {{ $t('LABELS.children-with-age') }}
            </p>
            <Counter
                :min="0"
                :max="maxChildren"
                :initial="initChildren"
                @changed="setChildrenNum"
            ></Counter>
        </div>
        <div>
            <div class="cabin-delegate__text">
                {{ $t('LABELS.capacity') }}: {{ numAdults + numChildren }} /
                {{ maxPeople }}
            </div>
            <div v-if="isAllInclusiveAvailable" class="cabin-delegate__text">
                <label class="cabin-delegate__label">
                    <input
                        type="checkbox"
                        class="cabin-delegate__checkbox"
                        v-model="allInclusive"
                        @change="updateCabinInfo"
                    />
                    {{ $t('LABELS.all-inclusive') }}
                </label>
            </div>
        </div>
        <div class="cabin-delegate__price-summary">
            <div class="cabin-delegate__text">
                {{ $t('LABELS.price-per-person') }}:
                {{ formatPriceText(avgPrice()) }}
            </div>
            <div class="cabin-delegate__text">
                {{ $t('LABELS.in-total') }}: {{ formatPriceText(totalPrice) }}
            </div>
        </div>
    </div>
</template>
<script>
import Counter from '../components/Counter.vue';
import vSelect from 'vue-select';
import formatPriceUtil from '../utilities/formatPrice';

import { LANGUAGE } from '@/env';

export default {
    components: {
        Counter,
        vSelect,
    },
    props: {
        prices: {
            type: Array,
            required: true,
        },
        maxPeople: {
            type: Number,
            default: 4,
        },
        initAdults: {
            type: Number,
            default: 0,
        },
        initChildren: {
            type: Number,
            default: 0,
        },
        initIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            numAdults: 0,
            numChildren: 0,
            totalPrice: 0,
            allInclusive: false,
            selectedPriceIndex: 0,
        };
    },
    methods: {
        calculateTotalPrice() {
            // Calculate the total price for selected adults
            let adultPrice = this.prices[this.selectedPriceIndex].adult
                .slice(0, this.numAdults)
                .reduce(
                    (sum, price) =>
                        sum + price[this.$store.currency].amount_decimal,
                    0
                );

            // Calculate the total price for selected children
            let childPrice = this.prices[this.selectedPriceIndex].child
                .slice(0, this.numChildren)
                .reduce(
                    (sum, price) =>
                        sum + price[this.$store.currency].amount_decimal,
                    0
                );

            // If allInclusive is true, add the all_inclusive_adult and all_inclusive_child prices
            if (this.allInclusive) {
                // For adults
                const allInclusiveAdultPrice =
                    this.prices[this.selectedPriceIndex].all_inclusive_adult[
                        this.$store.currency
                    ].amount_decimal;
                adultPrice += this.numAdults * allInclusiveAdultPrice;

                // For children
                const allInclusiveChildPrice =
                    this.prices[this.selectedPriceIndex].all_inclusive_child[
                        this.$store.currency
                    ].amount_decimal;
                childPrice += this.numChildren * allInclusiveChildPrice;
            }

            // Update total price
            this.totalPrice = adultPrice + childPrice;
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        formatPriceText(price) {
            return `${this.formatPrice(price)} ${
                this.$store.currency === 'czk'
                    ? 'Kč'
                    : this.$store.currency === 'eur'
                    ? '€'
                    : this.$store.currency
            }`;
        },
        avgPrice() {
            const totalPeople = this.numAdults + this.numChildren;
            return totalPeople ? this.totalPrice / totalPeople : 0;
        },
        updateCabinInfo() {
            this.calculateTotalPrice();
            this.emitCabinUpdate();
        },
        emitCabinUpdate() {
            this.$emit('update-cabin', {
                numAdults: this.numAdults,
                numChildren: this.numChildren,
                totalPrice: this.totalPrice,
                fare: this.prices[this.selectedPriceIndex].fare,
                uuid: this.prices[this.selectedPriceIndex].id,
                code: this.prices[this.selectedPriceIndex].code,
                allInclusive: this.allInclusive,
            });
        },
        emitCabinRemove() {
            this.$emit('remove-cabin');
        },
        setAdultsNum(newAdultsNum) {
            this.numAdults = newAdultsNum;
            this.updateCabinInfo();
        },
        setChildrenNum(newChildrenNum) {
            this.numChildren = newChildrenNum;
            this.updateCabinInfo();
        },
    },
    computed: {
        maxAdults() {
            return Math.min(
                this.prices[this.selectedPriceIndex].adult.length,
                this.maxPeople - this.numChildren
            );
        },
        maxChildren() {
            return Math.min(
                this.prices[this.selectedPriceIndex].child.length,
                this.maxPeople - this.numAdults
            );
        },
        isAllInclusiveAvailable() {
            const fare = this.prices[this.selectedPriceIndex];
            console.debug(fare);
            const allInclusiveAdultPrice =
                fare?.all_inclusive_adult[this.$store.currency].amount_decimal;
            const allInclusiveChildPrice =
                fare?.all_inclusive_child[this.$store.currency].amount_decimal;
            return allInclusiveAdultPrice > 0 || allInclusiveChildPrice > 0;
        },
    },
    watch: {
        selectedPriceIndex() {
            this.updateCabinInfo();
        },
        allInclusive() {
            this.updateCabinInfo();
        },
        isAllInclusiveAvailable(newVal) {
            if (!newVal && this.allInclusive) {
                this.allInclusive = false;
            }
        },
    },
    mounted() {
        console.debug(this.prices);
        this.numAdults = this.initAdults;
        this.numChildren = this.initChildren;
        this.selectedPriceIndex = this.initIndex;
        this.updateCabinInfo();
    },
};
</script>
