<template>
    <Head>
        <title>{{ $t('TITLES.homepage') }}</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main main--cruiser">
        <p>
            {{ $t('TEXTS.trip-not-available') }} <br />{{
                $t('TEXTS.try-later')
            }}
        </p>
        <router-link
            :to="getSearchUrl()"
            class="btn btn--blue btn--standalone"
            >{{ $t('LABELS.back-to-search') }}</router-link
        >
    </main>
    <Footer></Footer>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';

import { Head } from '@vueuse/head';

export default {
    components: {
        Footer,
        MainNav,
        Head,
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        getSearchUrl() {
            return `/vyhledat/#${this.$route.query.searchFilters}`;
        },
    },
    mounted() {},
    watch: {},
};
</script>
<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 4rem;
}
.main > p {
    text-align: center;
    font-size: 2.6rem;
    margin-bottom: 4rem;
}
</style>
