<template>
    <div class="cabin-showcase">
        <div class="cabin-showcase__not-available" v-if="!availability">
            {{ $t('LABELS.soldout') }}
        </div>
        <Swiper
            :slides-per-view="1"
            class="cabin-showcase__slider"
            :autoplay="swiperDelay"
            @swiper="saveSwiper"
        >
            <button
                class="
                    cabin-showcase__slider-btn cabin-showcase__slider-btn--prev
                "
                :class="{
                    'cabin-showcase__slider-btn--disabled': swiper?.isBeginning,
                    'cabin-showcase__slider-btn--hidden':
                        swiper?.isBeginning && swiper?.isEnd,
                }"
                @click="prevSlide"
            >
                <svg
                    class="
                        cabin-showcase__slider-icon
                        cabin-showcase__slider-icon--left
                    "
                >
                    <use
                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                    ></use>
                </svg>
            </button>
            <Swiper-slide v-for="(imgUrl, i) in images" :key="i"
                ><img
                    :src="imgUrl"
                    class="cabin-showcase__slide"
                    :class="{ 'cabin-showcase__slide--msc': msc }"
            /></Swiper-slide>
            <button
                class="
                    cabin-showcase__slider-btn cabin-showcase__slider-btn--next
                "
                :class="{
                    'cabin-showcase__slider-btn--disabled': swiper?.isEnd,
                    'cabin-showcase__slider-btn--hidden':
                        swiper?.isEnd && swiper?.isBeginning,
                }"
                @click="nextSlide()"
            >
                <svg
                    class="
                        cabin-showcase__slider-icon
                        cabin-showcase__slider-icon--right
                    "
                >
                    <use
                        href="/assets/imgs/icons/sprite.svg#simple-arrow"
                    ></use>
                </svg>
            </button>
        </Swiper>
        <div class="cabin-showcase__content">
            <div class="cabin-showcase__part">
                <h3 class="cabin-showcase__heading">{{ name }}</h3>
                <h4 v-if="location" class="cabin-showcase__subheading">
                    {{ location }}
                </h4>
            </div>
            <div class="cabin-showcase__part">
                <p class="cabin-showcase__prices">
                    <button
                        class="cabin-showcase__price-btn"
                        v-for="option in availablePackages"
                        :key="option.code"
                        @click="chooseCabin(option.code)"
                        :disabled="!availability"
                    >
                        <p class="cabin-showcase__price">
                            {{
                                `${formatPrice(
                                    prices?.[option.code][$store.currency]
                                        .amount_decimal
                                )} ${
                                    prices?.[option.code][$store.currency]
                                        .currency
                                }`
                            }}
                        </p>
                        <p class="cabin-showcase__price-label">
                            {{
                                option.label
                                    ? $t(option.label)
                                    : `${$t('TEXTS.with-package')} "${
                                          option.code
                                      }"`
                            }}
                        </p>
                    </button>
                </p>
                <p class="cabin-showcase__price-note">
                    {{ $t('LABELS.prices-shown-for') }}
                    <strong
                        >{{ adultsNum }} {{ inflectWord('adults', adultsNum)
                        }}{{
                            childrenNum > 0
                                ? ` a ${childrenNum} ${inflectWord(
                                      'children',
                                      childrenNum
                                  )}`
                                : ''
                        }}</strong
                    >
                </p>
            </div>
            <div class="cabin-showcase__part">
                <p class="cabin-showcase__note">
                    {{
                        msc
                            ? $t('TEXTS.service-fee-msc')
                            : $t('TEXTS.service-fee-costa')
                    }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';

import { Dictionary } from '../modules/Dictionary';
import formatPriceUtil from '../utilities/formatPrice';
import { LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: [
        'code',
        'images',
        'name',
        'location',
        'prices',
        'adultsNum',
        'childrenNum',
        'availability',
        'msc',
    ],
    emits: ['choseCabin'],
    data() {
        return {
            swiper: null,
            packageOptions: {
                MYCRUISE: 'TEXTS.with-my-cruise-package',
                PROMO: 'TEXTS.with-promo-package',
                PIND: 'TEXTS.with-last-minute-package',
                MYALLINC: 'TEXTS.with-all-inclusive-package',
                IND: 'TEXTS.with-individual-package',

                // MSC entries
                WAVE: 'TEXTS.with-wave-package',
                WAVEDRIN: 'TEXTS.with-wavedrin-package',
                BESTPR: 'TEXTS.with-bestpr-package',
                BPDRINK: 'TEXTS.with-bpdrink-package',
                BROKEN: 'TEXTS.with-promomkt-package',
                EARLYBKG: 'TEXTS.with-earlybkg-package',
                EBDRINK: 'TEXTS.with-ebdrink-package',
                LASTMIN: 'TEXTS.with-lastmin-package',
                LMDRINK: 'TEXTS.with-lmdrink-package',
                PROMOMKT: 'TEXTS.with-promomkt-package',
                PRDRINK: 'TEXTS.with-prdrink-package',
                PREXCDR: 'TEXTS.with-prexcdr-package',
                HBDRINKS: 'TEXTS.with-ebdrink-package',
                UNKNOWN: 'TEXTS.with-bestpriceflight-package', // Missing code for text
            },
        };
    },
    methods: {
        saveSwiper(swiper) {
            this.swiper = swiper;
        },
        nextSlide() {
            this.swiper.slideNext();
        },
        prevSlide() {
            this.swiper.slidePrev();
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        inflectWord(word, num) {
            return dict.getWord(word, num, LANGUAGE);
        },
        chooseCabin(packageCode) {
            this.$emit('choseCabin', { code: this.code, package: packageCode });
        },
    },
    computed: {
        swiperDelay() {
            return {
                delay: 3000,
            };
        },
        availablePackages() {
            // Create the base packages array
            const packages = Object.keys(this.prices)
                .filter((packageCode) => {
                    return this.prices[packageCode]?.czk?.amount_decimal > 0;
                })
                .map((packageCode) => ({
                    code: packageCode,
                    label: this.packageOptions[packageCode],
                }));

            // Treat MSC Yacht Club specially
            if (
                this.msc &&
                [
                    'YC1',
                    'YC2',
                    'YC3',
                    'YC4',
                    'YCP',
                    'YCD',
                    'YIN',
                    'YJD',
                ].includes(this.code)
            ) {
                let ultraLastMinutePackage = null;
                let ultraPackage = null;

                // -- Condition 1: LMDRINK and LASTMIN special check --
                // Only proceed if both packages are available
                const hasLMDRINK = packages.some(
                    (pkg) => pkg.code === 'LMDRINK'
                );
                const hasLASTMIN = packages.some(
                    (pkg) => pkg.code === 'LASTMIN'
                );
                if (hasLMDRINK && hasLASTMIN) {
                    // Use the current currency for price comparison
                    const lmDrinkPrice =
                        this.prices['LMDRINK']?.[this.$store.currency]
                            ?.amount_decimal;
                    const lastMinPrice =
                        this.prices['LASTMIN']?.[this.$store.currency]
                            ?.amount_decimal;
                    if (
                        lmDrinkPrice !== undefined &&
                        lastMinPrice !== undefined &&
                        lmDrinkPrice === lastMinPrice
                    ) {
                        ultraLastMinutePackage = {
                            code: 'LMDRINK',
                            label: 'TEXTS.with-ultra-all-inclusive-last-minute',
                        };
                    }
                }

                // -- Condition 2: All other packages (excluding LMDRINK and LASTMIN) have equal price --
                const remainingPackages = packages.filter(
                    (pkg) => pkg.code !== 'LMDRINK' && pkg.code !== 'LASTMIN'
                );
                if (remainingPackages.length > 0) {
                    const firstPrice =
                        this.prices[remainingPackages[0].code]?.[
                            this.$store.currency
                        ]?.amount_decimal;
                    const allEqualRemaining = remainingPackages.every((pkg) => {
                        return (
                            this.prices[pkg.code]?.[this.$store.currency]
                                ?.amount_decimal === firstPrice
                        );
                    });
                    if (allEqualRemaining) {
                        ultraPackage = {
                            code: remainingPackages[0].code,
                            label: 'TEXTS.with-ultra-all-inclusive',
                        };
                    }
                }

                // -- Return based on which conditions are met --
                if (ultraLastMinutePackage && ultraPackage) {
                    // Both special conditions are met; return a combination
                    return [ultraLastMinutePackage, ultraPackage];
                } else if (ultraLastMinutePackage) {
                    return [ultraLastMinutePackage];
                } else if (ultraPackage) {
                    return [ultraPackage];
                }
            }

            // Otherwise, return all available packages
            return packages;
        },
    },
};
</script>
