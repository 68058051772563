<template>
    <Head>
        <title>
            {{
                $t('TITLES.content-page', {
                    pageName: $t('LABELS.msc-cruises'),
                })
            }}
        </title>
        <meta
            name="description"
            :content="
                $t('META-DESCRIPTIONS.content-page', {
                    pageName: $t('LABELS.msc-cruises'),
                })
            "
        />
    </Head>
    <header
        :style="{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }"
    >
        <Main-nav></Main-nav>
        <Content-hero
            :style="{ flex: 1 }"
            background-img-url="/assets/imgs/content-headers/costa-cruises.jpg"
            :title="$t('LABELS.msc-cruises')"
        />
        <Content-under-hero
            :text="$t('TEXTS.content-costa-cruises-under-hero')"
        />
    </header>

    <NewVideoYT
        type="cruises"
        yt-url="https://www.youtube-nocookie.com/embed/G4Ee1lDt2jg"
        preview-url="/assets/imgs/content/msc-ship.jpg"
        class="costa-cruises-content__video"
    />

    <main class="main content-pages__main content-pages__main--costa-cruises">
        <ContentHalves
            :title="$t('LABELS.msc-cruises')"
            :text="[
                $t('TEXTS.msc-cruises-tradition-1'),
                $t('TEXTS.msc-cruises-tradition-2'),
                $t('TEXTS.msc-cruises-tradition-3'),
            ]"
            :button-text="$t('LABELS.order-cruise')"
            button-url="/vyhledat/"
            image-side="left"
            waves-location="bottom-left-inner"
            bigger-btn
            widerText
        >
            <div class="costa-cruises-content__gallery">
                <img
                    src="/assets/imgs/content/msc-history.jpg"
                    class="costa-cruises-content__gallery-main-img"
                />
                <img
                    src="/assets/imgs/content/msc-history2.jpg"
                    class="costa-cruises-content__gallery-second-img costa-cruises-content__gallery-second-img--smaller"
                />
            </div>
        </ContentHalves>
        <ContentHalves
            :title="$t('LABELS.msc-cruises-today')"
            :text="[
                $t('TEXTS.msc-cruises-now-1'),
                $t('TEXTS.msc-cruises-now-2'),
                $t('TEXTS.msc-cruises-now-3'),
                $t('TEXTS.msc-cruises-now-4'),
            ]"
            :button-text="$t('LABELS.order-cruise')"
            button-url="/vyhledat/"
            image-side="right"
            waves-location="lower-left"
            bigger-btn
            widerText
        >
            <div class="costa-cruises-content__gallery">
                <img
                    src="/assets/imgs/content/msc-kajuta-1.jpg"
                    class="costa-cruises-content__gallery-main-img"
                />
                <img
                    src="/assets/imgs/content/msc-interier-2.jpg"
                    class="costa-cruises-content__gallery-second-img costa-cruises-content__gallery-second-img--larger"
                />
            </div>
        </ContentHalves>
    </main>
    <ContactBar></ContactBar>
    <Footer></Footer>
</template>
<script>
import { Head } from '@vueuse/head';
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import Footer from '../../components/Footer';
import { TYPE, LANGUAGE } from '@/env';
import NewVideoYT from '../../components/NewVideoYT.vue';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        ContentUnderHero,
        Footer,
        ContentHalves,
        ContactBar,
        NewVideoYT,
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            cabinsDescription: [
                this.$t('TEXTS.about-cabin-text-1'),
                this.$t('TEXTS.about-cabin-text-2'),
                this.$t('TEXTS.about-cabin-text-3'),
                `${this.$t('TEXTS.about-cabin-text-4')}<br>
                ${this.$t('LABELS.inner-cabin')}<br>
                ${this.$t('LABELS.sea-view-cabin')}<br>
                ${this.$t('LABELS.terrace-cabin')}<br>
                ${this.$t('LABELS.apartman-cabin')}<br>
                ${this.$t('LABELS.luxury-apartman-cabin')}`,
            ],
        };
    },
};
</script>
