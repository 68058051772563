<template>
    <div
        :class="`features-dash__wrapper${
            size ? ` features-dash__wrapper--${size}` : ''
        }`"
    >
        <section :class="`features-dash features-dash--${type}`">
            <header class="features-dash__header">
                <h5 class="features-dash__sub-heading">{{ subHeading }}</h5>
                <h4 class="features-dash__heading">{{ heading }}</h4>
            </header>
            <main class="features-dash__main">
                <ul class="features-dash__list">
                    <li
                        class="features-dash__feature"
                        v-for="(feature, i) in features"
                        :key="i"
                    >
                        <svg
                            class="features-dash__list-icon features-dash__list-icon--plus"
                            v-if="type === 'additional'"
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#plus"
                            ></use>
                        </svg>
                        <svg
                            class="features-dash__list-icon features-dash__list-icon--check-mark"
                            v-else
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#check-mark"
                            ></use>
                        </svg>
                        <span v-html="feature"></span>
                    </li>
                </ul>
            </main>
            <footer class="features-dash__footer" v-if="footerText">
                <p class="features-dash__footer-text" v-html="footerText"></p>
            </footer>
        </section>
        <div class="features-dash__cclub-container" v-if="size === 'fullwidth'">
            <svg v-if="!hideLogo" class="features-dash__cclub-logo">
                <use href="/assets/imgs/icons/sprite.svg#cclub"></use>
            </svg>
        </div>
        <img
            v-if="wavesLocation"
            src="/assets/imgs/waves.png"
            :class="`features-dash__waves features-dash__waves--${wavesLocation}`"
        />
    </div>
</template>
<script>
export default {
    props: {
        subHeading: String,
        heading: String,
        features: Array,
        footerText: String,
        type: String,
        size: String,
        wavesLocation: String,
        hideLogo: Boolean,
    },
};
</script>
