<template>
    <div class="gallery">
        <button
            class="gallery__btn gallery__btn--prev"
            :class="{
                'gallery__btn--disabled':
                    !loop && (swiper?.isBeginning || isLeftEnd),
                'gallery__btn--yachtas': type === 'yachtas',
                'gallery__btn--cruises': type === 'cruises',
            }"
            @click="prevSlide"
        >
            <svg class="gallery__btn-icon gallery__btn-icon--left">
                <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
            </svg>
        </button>
        <Swiper
            :modules="swiperModules"
            :slides-per-view="1"
            :space-between="40"
            :auto-height="true"
            :loop="loop"
            :autoplay="swiperDelay"
            :breakpoints="{
                768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            }"
            class="gallery__slider"
            @swiper="saveSwiper"
        >
            <Swiper-slide v-for="img of imagesWithIds" :key="img.id">
                <img
                    class="gallery__slide"
                    v-if="img.type.includes('image')"
                    :src="img.src"
                    @click="showLightbox(img.id)"
                />
                <img
                    class="gallery__slide"
                    v-else-if="img.type.includes('video')"
                    :src="img.thumb"
                    @click="showLightbox(img.id)"
                />
                <a
                    class="gallery__slide"
                    v-else-if="img.type.includes('pdf')"
                    :href="img.src"
                    target="_blank"
                >
                    <img :src="img.thumb" />
                </a>
            </Swiper-slide>
            <Swiper-slide v-for="_ in fillImagesNumber" :key="_"></Swiper-slide>
        </Swiper>
        <button
            class="gallery__btn gallery__btn--next"
            :class="{
                'gallery__btn--disabled':
                    !loop && (swiper?.isEnd || isRightEnd),
                'gallery__btn--yachtas': type === 'yachtas',
                'gallery__btn--cruises': type === 'cruises',
            }"
            @click="nextSlide()"
        >
            <svg class="gallery__btn-icon gallery__btn-icon--right">
                <use href="/assets/imgs/icons/sprite.svg#simple-arrow"></use>
            </svg>
        </button>

        <Fancybox
            :data="this.imagesWithIds"
            :selected-id="lightbox.selectedId"
            :visible="lightbox.shown"
            @closed="() => (lightbox.shown = false)"
        />
    </div>
</template>
<script>
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import Fancybox from '@/components/Fancybox';

import { withGeneratedIds } from '@/utilities';

export default {
    props: {
        reset: Number,
        images: Array,
        type: String,
        loop: Boolean,
        delay: Number,
    },
    components: {
        Fancybox,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiper: null,
            windowWidth: window.innerWidth,
            lightbox: {
                selectedId: '',
                shown: false,
            },
            swiperModules: [Autoplay],
        };
    },
    methods: {
        saveSwiper(swiper) {
            this.swiper = swiper;
        },
        nextSlide() {
            this.swiper.slideNext();
        },
        prevSlide() {
            this.swiper.slidePrev();
        },
        showLightbox(id) {
            this.lightbox.selectedId = id;
            this.lightbox.shown = true;
        },
    },
    computed: {
        imagesWithIds() {
            return withGeneratedIds(this.images);
        },
        swiperDelay() {
            if (this.delay) {
                return {
                    delay: this.delay,
                };
            }
            return false;
        },
        isRightEnd() {
            if (this.loop) {
                return false;
            }
            return (
                this.swiper?.activeIndex + this.slidesPerView ==
                    this.images?.length ||
                this.slidesPerView > this.images?.length
            );
        },
        isLeftEnd() {
            if (this.loop) {
                return false;
            }
            // It's a trigger, don't manupulate with the console log
            console.log(this.images?.length);
            return this.swiper?.activeIndex === 0;
        },
        slidesPerView() {
            if (this.windowWidth >= 1024) {
                return 3;
            } else if (this.windowWidth >= 768) {
                return 2;
            }
            return 1;
        },
        fillImagesNumber() {
            if (this.loop) {
                return 0;
            }

            let numberPerView = this.slidesPerView;

            let fillNumber = numberPerView - this.images?.length;
            if (!(fillNumber >= 0)) {
                fillNumber = 0;
            }

            return Array(fillNumber).fill(0);
        },
    },
    mounted() {
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    watch: {
        reset() {
            this.swiper.slideReset();
        },
    },
};
</script>
