<template>
    <Head>
        <title>
            {{
                $t('TITLES.content-page', {
                    pageName: $t('LABELS.how-it-works'),
                })
            }}
        </title>
        <meta
            name="description"
            :content="
                $t('META-DESCRIPTIONS.content-page', {
                    pageName: $t('LABELS.how-it-works'),
                })
            "
        />
    </Head>

    <header
        :style="{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
        }"
    >
        <Main-nav></Main-nav>
        <Content-hero
            :style="{ flex: 1 }"
            background-img-url="/assets/imgs/content-headers/for-firms.jpg"
            :title="$t('LABELS.how-it-works')"
            :sub-title="$t('LABELS.info-about-msc-cruises')"
        />
        <Content-under-hero
            :text="$t('TEXTS.content-how-it-works-under-hero')"
        />
    </header>

    <main class="main content-pages__main">
        <div class="content-pages__how-it-works">
            <h2
                class="content-pages__how-it-works__title content-pages__how-it-works__title--centered"
            >
                Proč a jak si objednat plavbu s MSC Cruises
            </h2>

            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Jednoduchá a Pohodlná Rezervace</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    S MSC Cruises si můžete plavbu rezervovat rychle a pohodlně
                    dvěma způsoby – online nebo s pomocí našich odborníků.
                    Plánování dokonalé dovolené na moři nebylo nikdy snazší!
                </p>
            </section>

            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>On-line Rezervace a Platební Možnosti</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    Jsme jediní v České republice, kdo nabízí možnost rezervace
                    plavby s MSC Cruises jednoduše online. <br />
                    • <b>Aktuální dostupnost a ceny:</b> Na našem webu najdete
                    vždy aktuální nabídku kajut i cen.<br />
                    • <b>Okamžité potvrzení:</b> Rezervace je automaticky
                    potvrzena ihned po zaplacení. Nemusíte čekat na zpětnou
                    reakci nebo potvrzení dostupnosti.<br />
                    • <b>Flexibilní doplňkové služby:</b> Po rezervaci si můžete
                    kdykoliv dokoupit další služby.<br />
                </p>
            </section>

            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Rezervace s expertem na plavby</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    Pokud máte specifické požadavky nebo je to vaše první
                    plavba, jsme tu pro vás: <br />
                    • <b>Osobní přístup:</b> Zavolejte nám nebo napište, a my s
                    vámi probereme všechny detaily vaší dovolené.<br />
                    • <b>Odborné poradenství:</b> Naši specialisté vám vysvětlí,
                    jak vše na lodi funguje, a vytvoří nabídku podle vašich
                    představ.
                </p>
            </section>

            <section>
                <h3 class="content-pages__how-it-works__subtitle">
                    <strong>Všechny výhody MSC Cruises u nás</strong>
                </h3>
                <p class="content-pages__how-it-works__text">
                    Jako oficiální partner MSC Cruises nabízíme všechny
                    benefity, které tato společnost poskytuje, bez jakýchkoliv
                    přirážek:<br />
                    • <b>Členství v MSC Voyagers Club:</b> Využijte slevy na
                    vybrané plavby, exkluzivní nabídky a další výhody.<br />
                    • <b>Slevy a bonusy:</b> Získejte výhodnější ceny, bonusy za
                    věrnost nebo upgrade kajuty zdarma. <br /><br />
                    <b
                        >S MSC Cruises máte záruku pohodlné rezervace, odborné
                        podpory a všech výhod, které tato prestižní lodní
                        společnost nabízí.</b
                    >
                </p>
            </section>
        </div>

        <div class="content-pages__how-it-works__tabs">
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'cabins')"
            >
                Experiences
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'food')"
            >
                {{ $t('LABELS.food') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'transport-to-port')"
            >
                {{ $t('LABELS.transport-to-port') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'what-to-bring')"
            >
                {{ $t('LABELS.what-to-bring') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'luggage')"
            >
                {{ $t('LABELS.luggage') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'restrictions')"
            >
                {{ $t('LABELS.restrictions') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'fun-on-ship')"
            >
                {{ $t('LABELS.fun-on-ship') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'excursions')"
            >
                {{ $t('LABELS.excursions') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'portal-msc')"
            >
                {{ $t('LABELS.portal-msc') }}
            </button>
            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'mscyc')"
            >
                {{ $t('LABELS.mscyc') }}
            </button>

            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'internet')"
            >
                {{ $t('LABELS.internet') }}
            </button>

            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'sport')"
            >
                {{ $t('LABELS.sport') }}
            </button>

            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'packages')"
            >
                {{ $t('LABELS.packages') }}
            </button>

            <button
                class="content-pages__how-it-works__tabs-links"
                @click="openTab($event, 'drinks')"
            >
                {{ $t('LABELS.drinks') }}
            </button>
        </div>

        <section>
            <!-- TAB: Cabins -->
            <div id="cabins" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    title="Bella Experience"
                    :text="[
                        '<b>Cenově dostupná plavba s kvalitními službami</b>',
                        'Nejlepší dostupná kajuta v rámci zvolené kategorie přidělená plavební společností.',
                        'Přístup ke všem hlavním aktivitám: restaurace, bufet, divadelní představení, bazén, fitness centrum a sportovní zařízení.',
                        'Není možné si vybrat umístění kajuty ani čas večeře.',
                        '<br>',
                        '<h3>Fantastica Experience</h3>',
                        '<b>Více pohodlí a flexibilita</b>',
                        'Všechny výhody balíčku Bella + možnost výběru kajuty a času večeře.',
                        'Lepší umístění kajut na lodi.',
                        'Snídaně na pokoji zdarma a přednostní rezervace večeří.',
                        '20% sleva na předplacené balíčky ve specializovaných restauracích.',
                        '<br>',
                        '<h3>Aurea Experience</h3>',
                        '<b>Komfort a relaxace s exkluzivními výhodami</b>',
                        'Všechny výhody balíčku Bella + možnost výběru kajuty a času večeře.',
                        'Lepší umístění kajut na lodi.',
                        'Snídaně na pokoji zdarma a přednostní rezervace večeří.',
                        '20% sleva na předplacené balíčky ve specializovaných restauracích.',
                        '<br>',
                        '<h3>MSC Yacht Club</h3>',
                        '<b>Luxusní a exkluzivní zážitek</b>',
                        '24hodinový osobní servis: Nepřetržitá péče butlera a služby concierge.',
                        'Privátní část lodi: Přístup do Top Sail Lounge, soukromé restaurace a sluneční terasy s bazénem a vířivkami.',
                        'Gastronomické zážitky: Stravování v privátní restauraci s flexibilními časy večeří a širokým výběrem prémiových nápojů.',
                        'Wellness a relaxace: Neomezený přístup do Termální zóny v MSC Aurea Spa a 10% sleva na procedury.',
                    ]"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    image-side="left"
                    waves-location="bottom-right"
                    bigger-btn
                    widerText
                >
                    <GridGallery
                        variant="1"
                        :imgsUrls="
                            [
                                'VE_Suite 1.jpg',
                                'DI_Balcony 2 1.jpg',
                                'VE_Bathroom 1.jpg',
                                'SM_Suite 5 1.jpg',
                                'DE_Balcony 2 2.jpg',
                                'SM_Suite 4 1.jpg',
                                'FI_Balcony 2 1.jpg',
                                'FO_Outside Window 1.jpg',
                                'DI_Suite 1.jpg',
                            ].map((file) => `/assets/imgs/content/${file}`)
                        "
                    ></GridGallery>
                </ContentHalves>
            </div>

            <!-- TAB: Sport -->
            <div id="sport" class="content-pages__how-it-works__tabs-content">
                <div class="content-pages__how-it-works__two-columns">
                    <div>
                        <h3 class="content-pages__how-it-works__title">
                            Sports & Fitness – aktivní dovolená na palubě
                        </h3>
                        <p>
                            Plavba na moři není jen o odpočinku a relaxaci, ale
                            také o příležitosti zůstat v kondici a užít si
                            zábavný pohyb. Na lodích MSC najdete širokou nabídku
                            sportovních aktivit, moderní fitness centra a další
                            možnosti, které potěší jak rekreační sportovce, tak
                            i nadšené fitness nadšence.
                        </p>
                        <br />

                        <h3>Fitness centrum</h3>
                        <p>
                            Každá loď MSC je vybavena moderním fitness centrem s
                            nejnovějšími stroji a vybavením:
                        </p>
                        <ul>
                            <li>
                                Kardiovaskulární stroje – běžecké pásy,
                                rotopedy, eliptické trenažéry.
                            </li>
                            <li>
                                Posilovací zóna – činky, stroje na posilování a
                                funkční trénink.
                            </li>
                            <li>
                                Skupinové lekce – zumba, pilates, jóga, spinning
                                a další programy pod vedením profesionálních
                                instruktorů.
                            </li>
                        </ul>
                        <b>TIP:</b> Lekce jsou často zdarma, některé
                        specializované programy je možné zakoupit za příplatek.
                        <br />
                    </div>
                    <div>
                        <h3>Sportovní zóny a aktivity</h3>
                        <p>
                            Pro ty, kteří dávají přednost týmovým sportům a
                            aktivitám pod širým nebem, nabízí lodě MSC:
                        </p>
                        <ul>
                            <li>
                                Sportovní hřiště – basketbal, volejbal, tenis a
                                malý fotbal.
                            </li>
                            <li>
                                Organizované turnaje – zábavná utkání a soutěže
                                pro všechny věkové kategorie.
                            </li>
                            <li>
                                Stolní tenis a minigolf – ideální zábava pro
                                rodiny s dětmi.
                            </li>
                        </ul>
                        <br />

                        <h3>Běžecká dráha</h3>
                        <p>
                            Pokud rádi běháte nebo chodíte, můžete si užít svůj
                            trénink na běžecké dráze s jedinečným výhledem na
                            oceán. Dráha je přístupná během celého dne a je
                            ideální pro ranní rozcvičku i večerní relaxační
                            procházku.
                        </p>
                        <br />

                        <h3>Osobní trénink</h3>
                        <p>
                            Potřebujete sestavit cvičební plán na míru? Na
                            palubě máte možnost využít služby osobního trenéra,
                            který vám poradí a sestaví program podle vašich
                            potřeb a cílů.
                        </p>
                    </div>
                </div>
                <div class="content-pages__how-it-works__text">
                    <strong>
                        Užijte si aktivní dovolenou plnou pohybu a zábavy. Ať už
                        jste vášnivý sportovec nebo hledáte odpočinkový pohyb s
                        výhledem na moře, lodě MSC vám nabídnou vše, co
                        potřebujete.
                    </strong>
                </div>
                <GridGallery
                    variant="2"
                    :imgsUrls="
                        [
                            'VE_Suite 1.jpg',
                            'DI_Balcony 2 1.jpg',
                            'VE_Bathroom 1.jpg',
                            'SM_Suite 5 1.jpg',
                            'DE_Balcony 2 2.jpg',
                            'SM_Suite 4 1.jpg',
                            'FI_Balcony 2 1.jpg',
                            'FO_Outside Window 1.jpg',
                            'DI_Suite 1.jpg',
                        ].map((file) => `/assets/imgs/content/${file}`)
                    "
                >
                </GridGallery>
            </div>

            <!-- TAB: Internet -->
            <div
                id="internet"
                class="content-pages__how-it-works__tabs-content"
            >
                <div class="content-pages__how-it-works__two-columns">
                    <div>
                        <h3 class="content-pages__how-it-works__title">
                            Internet na palubě – zůstaňte ve spojení
                        </h3>
                        <p>
                            Buďte online i během plavby. Lodní společnost MSC
                            nabízí výhodné internetové balíčky, které si můžete
                            zakoupit předem za lepší cenu.
                        </p>
                        <br />

                        <h3>Proč si internetový balíček pořídit?</h3>
                        <ul>
                            <li>Ušetříte 20 % při zakoupení předem.</li>
                            <li>
                                Připojení k internetu kdykoliv během celé
                                plavby.
                            </li>
                            <li>
                                Balíčky pro různé potřeby – od běžného surfování
                                až po streamování.
                            </li>
                        </ul>
                        <br />

                        <h3>Dostupné internetové balíčky</h3>
                        <b>Balíček pro prohlížení webu</b>
                        <ul>
                            <li>Platnost: Celá plavba</li>
                            <li>
                                Co zahrnuje: Prohlížení webu, e-maily, chatování
                                přes aplikace.
                            </li>
                            <li>
                                Ideální pro: Ty, kteří nepotřebují streamovat
                                videa či hudbu.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3>Balíček pro prohlížení a streamování</h3>
                        <ul>
                            <li>Platnost: Celá plavba</li>
                            <li>
                                Co zahrnuje: Vše z balíčku pro prohlížení webu +
                                streamování videí, hudby a videohovory.
                            </li>
                            <li>
                                Ideální pro: Ty, kteří chtějí sdílet obsah na
                                sociálních sítích nebo sledovat online videa.
                            </li>
                        </ul>
                        <br />

                        <h3>Jak balíček zakoupit?</h3>
                        <ul>
                            <li>
                                Rezervace předem: Nejlepší cena, stačí nám
                                napsat a my vám internetový balíček na vybraný
                                počet zařízení zarezervujeme.
                            </li>
                            <li>
                                Na palubě: Možnost zakoupit pouze za standardní
                                cenu.
                            </li>
                            <li>
                                Balíček je možné zakoupit na 1 až 4 zařízení: Po
                                registraci zařízení již není možné zařízení
                                změnit.
                            </li>
                        </ul>
                        <br />
                    </div>
                </div>
                <div class="content-pages__how-it-works__text">
                    <strong>
                        Využijte internetové balíčky a zůstaňte ve spojení, ať
                        už jste kdekoliv na moři.
                    </strong>
                </div>
                <GridGallery
                    variant="2"
                    :imgsUrls="
                        [
                            'VE_Suite 1.jpg',
                            'DI_Balcony 2 1.jpg',
                            'VE_Bathroom 1.jpg',
                            'SM_Suite 5 1.jpg',
                            'DE_Balcony 2 2.jpg',
                            'SM_Suite 4 1.jpg',
                            'FI_Balcony 2 1.jpg',
                            'FO_Outside Window 1.jpg',
                            'DI_Suite 1.jpg',
                        ].map((file) => `/assets/imgs/content/${file}`)
                    "
                >
                </GridGallery>
            </div>

            <!-- TAB: nápoje Balíčky -->
            <div id="drinks" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    title="Nápojové balíčky"
                    :text="[
                        '<h3>Nápojové balíčky na vaši plavbu – pohodlí, úspora, zážitek</h3>',
                        'Chcete si na plavbě užít oblíbené nápoje bez starostí? Nápojové balíčky jsou ideálním řešením! Dopřejte si neomezený výběr v restauracích, barech i u bazénu a navíc ušetříte, pokud si balíček objednáte předem.',
                        '<br>',
                        '<h3>Jaký balíček je pro vás nejlepší?</h3>',
                        '•	Easy balíček – Základní volba: Nealkoholické nápoje neomezeně + až 15 alkoholických nápojů denně (koktejly, pivo, víno).',
                        '•	Easy Plus balíček – Pro náročnější: Rozšířený výběr nápojů až do hodnoty 9 € (např. značkové koktejly a lihoviny).',
                        '•	Premium Extra balíček – Luxusní zážitek: Prémiové značky nápojů do hodnoty 14 € a 25% sleva na láhve vín.',
                        '•	Nealkoholický balíček – Skvělá volba pro ty, kdo nepijí alkohol: Nealkoholické koktejly, džusy, kávy a čokoláda neomezeně.',
                        '•	Dětský balíček – Pro mladé cestovatele: Nealko nápoje, ovocné šťávy, zmrzlina z bufetu.',
                        '<br>',
                        '<h3>Proč objednat předem?</h3>',
                        '•	Ušetříte až 15 %.',
                        '•	Jistota pohodlí už od prvního dne plavby.',
                        '•	Balíček lze zakoupit pouze pro všechny osoby v kajutě.',
                        '<br>',
                        '<h3>Jak objednat?</h3>',
                        '•	Online při rezervaci plavby – nejlepší cena a žádné starosti.',
                        '•	Na lodi první den – s dodatečným poplatkem.',
                        '<br>',
                        'Vyberte si balíček a užijte si svou plavbu bez počítání a s pohodlím all-inclusive!',
                    ]"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    image-side="left"
                    waves-location="bottom-right"
                    bigger-btn
                    widerText
                >
                    <GridGallery
                        variant="1"
                        :imgsUrls="
                            [
                                'VE_Suite 1.jpg',
                                'DI_Balcony 2 1.jpg',
                                'VE_Bathroom 1.jpg',
                                'SM_Suite 5 1.jpg',
                                'DE_Balcony 2 2.jpg',
                                'SM_Suite 4 1.jpg',
                                'FI_Balcony 2 1.jpg',
                                'FO_Outside Window 1.jpg',
                                'DI_Suite 1.jpg',
                            ].map((file) => `/assets/imgs/content/${file}`)
                        "
                    ></GridGallery>
                </ContentHalves>
            </div>

            <!-- TAB: Balíčky -->
            <div
                id="packages"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    title="Balíčky"
                    :text="[
                        '<h3>Doplňkové balíčky na palubě – zpříjemněte si svou plavbu</h3>',
                        'Lodní společnost MSC nabízí širokou škálu balíčků, které vám zpříjemní a usnadní pobyt na lodi. Ať už hledáte relaxaci, zábavu pro celou rodinu, nezapomenutelné vzpomínky nebo pohodlí v podobě praktických služeb, vše můžete mít za výhodnější cenu při rezervaci předem..',
                        '<br>',
                        '<h3>Relaxace na moři – lázeňské balíčky</h3>',
                        'Dopřejte si během plavby zasloužený odpočinek a regeneraci v MSC Aurea Spa:',
                        '•	Balijská masáž: Uvolnění svalového napětí a obnovení vitality (50/80 min)..',
                        '•	Termální zóna: Celodenní přístup do sauny a parní lázně',
                        '•	Masáž suché flotace (vybrané lodě): Zážitek nulové gravitace spojený s masáží.',
                        '•	Masáž himálajskými solnými kameny (vybrané lodě): Uvolnění svalů a detoxikace.',
                        '•	Sezení v Iyashi Dome (vybrané lodě): Intenzivní detoxikace a regenerace..',
                        '<br>',
                        'Tip: Při zakoupení předem můžete ušetřit až 55 % oproti palubním cenám.',
                        '<br>',
                        '<h3>Foto a video balíčky – uchovejte si vzpomínky</h3>',
                        'Zachyťte nejkrásnější okamžiky z plavby v profesionální kvalitě:',
                        '•	All Inclusive balíček digitálních fotografií: Neomezený počet digitálních fotografií s dodatečnými slevami na tisk.',
                        '•	Balíček fotografií ze všech událostí: Balíček 30–150 tištěných fotografií v různých velikostech.',
                        '•	Mix & Match: Balíček 20 fotografií dle vlastního výběru se slevou na digitální soubory.',
                        '<br>',
                        'Tip: Zakoupením balíčku předem ušetříte až 80 %.',
                        '<br>',
                        '<h3>Fun Pass – zábava pro celou rodinu</h3>',
                        'Využijte neomezený přístup k atrakcím a aktivitám na palubě:',
                        '•	Simulátory, bowling, interaktivní hry a další.',
                        '•	Možnost kombinace různých her pro maximální zážitek.',
                        '<br>',
                        'Tip: Zakoupením předem získáte výhodnější cenu a zábavu si užijete už od prvního dne plavby.',
                        '<br>',
                        '<h3>Prádelní balíčky – cestujte nalehko</h3>',
                        'Zapomeňte na starosti s praním a žehlením:',
                        '•	Kompletní služby praní, žehlení a skládání oblečení.',
                        '•	Profesionální a rychlá služba po celou dobu plavby.',
                        '<br>',
                        'Tip: Při zakoupení předem získáte výhodnější ceny.',
                        '<br>',
                        '<h3>Svatební balíčky – váš nezapomenutelný den</h3>',
                        'Řekněte si „ano“ na moři s jedinečným výhledem:',
                        '•	Organizace a koordinace obřadu.',
                        '•	Dekorace a květinová výzdoba.',
                        '•	Možnost uspořádání svatební hostiny.',
                        '•	Profesionální fotografické služby.',
                        '<br>',
                        'Svatební balíčky je třeba objednat předem, aby váš den byl naprosto dokonalý..',
                    ]"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    image-side="left"
                    waves-location="bottom-right"
                    bigger-btn
                    widerText
                >
                    <GridGallery
                        variant="1"
                        :imgsUrls="
                            [
                                'VE_Suite 1.jpg',
                                'DI_Balcony 2 1.jpg',
                                'VE_Bathroom 1.jpg',
                                'SM_Suite 5 1.jpg',
                                'DE_Balcony 2 2.jpg',
                                'SM_Suite 4 1.jpg',
                                'FI_Balcony 2 1.jpg',
                                'FO_Outside Window 1.jpg',
                                'DI_Suite 1.jpg',
                            ].map((file) => `/assets/imgs/content/${file}`)
                        "
                    ></GridGallery>
                </ContentHalves>
            </div>

            <!-- TAB: Food -->
            <div id="food" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    title="Stravování"
                    :text="[
                        'Na palubě lodi si můžete užít plnou penzi, která zahrnuje snídani, oběd, večeři, občerstvení během dne i večerní bufet. Ať už dáváte přednost formálním servírovaným restauracím nebo uvolněné atmosféře bufetů, na výběr máte vždy pestrou nabídku pokrmů.',
                        '<h3>Hlavní restaurace</h3>',
                        '<br>',
                        '<h3>Snídaně a obědy</h3>',
                        'Navštivte servírované restaurace, kde vás personál usadí a můžete si vybrat z pestrého menu.',
                        '<br>',
                        '<h3>Večeře</h3>',
                        'Večeře jsou rozděleny do dvou sezení – obvykle kolem 19:00 a 21:00 (časy se liší dle lodě a itineráře).',
                        '•	Dress code: Smart casual (např. dlouhé kalhoty a polotričko).',
                        '<br>',
                        '<h3>Hlavní restaurace</h3>',
                        'Pokud preferujete volnější styl stravování, bufetové restaurace jsou k dispozici:',
                        '•	Otevírací doba: Od časného rána až do noci (většinou do 2:00).',
                        '•	Nabídka zahrnuje teplé a studené pokrmy, pizzu, sendviče, dezerty a další.:',
                        '•	Bufet je samoobslužný.',
                        '<br>',
                        '<h3>Specializované restaurace (à la carte)</h3>',
                        'Chcete si dopřát něco výjimečného? MSC nabízí restaurace zaměřené na různé světové kuchyně:',
                        '•	Ocean Cay Seafood: Čerstvé mořské plody.',
                        '•	Chef Yamaguchi: Asijské speciality.',
                        '•	The Butcher’s Cut: Americký steakhouse.',
                        '•	Hola Tapas by Ramon Freixa: Španělské tapas.',
                        '•	L’Atelier Bistrot: Francouzská kuchyně.',
                        '•	Kaito Sushi Bar: Autentické japonské sushi.',
                        '•	Kaito Teppanyaki: Zážitek z asijské kuchyně připravované přímo před vámi.',
                        '•	Hola! Tacos & Cantina: Mexické tacos a nachos.',
                        '•	Eataly: Italské speciality s domácími těstovinami (na MSC World America).',
                        '•	Paxos Greek Restaurant: Mořské plody, ryby a živá hudba (na MSC World America).',
                        '<br>',
                        'Důležité: Tyto restaurace nejsou zahrnuty v ceně plavby a jsou za příplatek. Doporučujeme rezervaci předem, případně zakoupení zvýhodněných balíčků pro 2–4 gastronomické zážitky.',
                        '<br>',
                        '<h3>Pokojový servis</h3>',
                        'Máte chuť na klidný den ve své kajutě? Využijte pokojový servis, který je dostupný 24 hodin denně:',
                        '• Donáška jídla a nápojů za příplatek.',
                        '• V případě nemoci je služba zdarma',
                        '<br>',
                        '<h3>Zdravotní omezení a diety</h3>',
                        '•	Bezlepková jídla.',
                        '•	Vegetariánská strava.',
                        '•	Strava pro diabetiky',
                        '<br>',
                        '<h3>Důležité informace</h3>',
                        '•	Nápoje zdarma: Voda a některé nealkoholické nápoje z automatů během jídel.',
                        '•	Výlety: Na výletech mimo loď není možné zajistit balíčky s jídlem, ale některé organizované výlety mohou oběd zahrnovat.',
                        '•	Příplatky v USA a Karibiku: Na lodích plujících z těchto oblastí může být účtován poplatek za další hlavní jídla nad rámec menu.',
                        '<b>Každý den na palubě je gurmánským zážitkem. Užijte si rozmanitost světových kuchyní a jedinečnou atmosféru, která uspokojí i ty nejnáročnější.</b>',
                    ]"
                    imageUrl="/assets/imgs/content/d8d5f60d-7956-4f68-9228-5e737d825597 1.jpg"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
                <h2 class="content-pages__heading-2">
                    <b class="content-pages__bold">{{
                        $t('TEXTS.how-it-works-heading-1')
                    }}</b>
                </h2>
                <div
                    class="features-dash__container features-dash__container--grid"
                >
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-cruise')"
                        :features="[
                            $t('TEXTS.my-cruise-feature-1'),
                            $t('TEXTS.my-cruise-feature-2'),
                            $t('TEXTS.my-cruise-feature-3'),
                            $t('TEXTS.my-cruise-feature-4'),
                        ]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.all-inclusive')"
                        :features="[
                            $t('TEXTS.all-inclusive-feature-1'),
                            $t('TEXTS.all-inclusive-feature-2'),
                            $t('TEXTS.all-inclusive-feature-3'),
                            $t('TEXTS.all-inclusive-feature-4'),
                        ]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.super-all-inclusive')"
                        :features="[
                            $t('TEXTS.super-all-inclusive-feature-1'),
                            $t('TEXTS.super-all-inclusive-feature-2'),
                        ]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-drinks-plus')"
                        :features="[$t('TEXTS.my-drinks-plus-feature-1')]"
                        type="info"
                    ></FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-soft-drinks')"
                        :features="[$t('TEXTS.my-soft-drinks-feature-1')]"
                        type="info"
                        wavesLocation="bottom-left"
                    >
                    </FeaturesDash>
                    <FeaturesDash
                        :subHeading="$t('LABELS.catering-package')"
                        :heading="$t('LABELS.my-drinks-young')"
                        :features="[$t('TEXTS.my-drinks-young-feature-1')]"
                        type="info"
                    ></FeaturesDash>
                </div>
            </div>

            <!-- TAB: Transport-to-port -->
            <div
                id="transport-to-port"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.transport-to-port')"
                    :text="[
                        $t('TEXTS.transport-to-port-text-1'),
                        $t('TEXTS.transport-to-port-text-2'),
                        $t('TEXTS.transport-to-port-text-3'),
                        $t('TEXTS.transport-to-port-text-4'),
                    ]"
                    imageUrl="/assets/imgs/content/travel.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                />
            </div>

            <!-- TAB: What-to-bring -->
            <div
                id="what-to-bring"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.what-to-bring')"
                    :text="[
                        '<h3>Oblečení a dress code</h3>',
                        '•	Přes den: Volné a pohodlné oblečení – užijte si svou dovolenou v plné pohodě.',
                        '•	Večer: Doporučený dress code je formální:',
                        'o	Pro pány: Dlouhé kalhoty a košile.',
                        'o	Pro dámy: Šaty nebo elegantní kostýmek.',
                        '•	Tematické večery: Během plavby můžete zažít speciální akce, jako jsou „GALA Night“, „White Party“. Informace o programu dostanete na palubě.',
                        '<br>',
                        '<h3>Zakázané položky na palubě</h3>',
                        'Některé věci není možné na loď přinést z bezpečnostních důvodů. Tyto položky budou při nalodění zabaveny:',
                        '•	Elektronická zařízení: Žehličky na vlasy, kulmy, vařiče nebo jiná zařízení s topným tělesem.',
                        '•	Ostré předměty: Nože, nůžky, jehlice.',
                        '•	Drony: Jsou na lodi zakázány.',
                        '•	Jídlo a nápoje: Domácí potraviny a nápoje nejsou povoleny.',
                        +'<br>',
                        'Tip: Pro kompletní seznam zakázaných položek si před cestou ověřte aktuální pravidla společnosti MSC Cruises.',
                        '<br>',
                        'Doporučení: Přibalte si elegantní oblečení na tematické večery a nezapomeňte na pohodlnou obuv pro procházky po lodi nebo výlety na pevnině.',
                    ]"
                    imageUrl="/assets/imgs/content/custom-transport.jpg"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
            </div>

            <!-- TAB: Luggage -->
            <div id="luggage" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    :title="$t('LABELS.luggage')"
                    :text="[
                        '<h3>Příprava zavazadel před naloděním</h3>',
                        '•	Štítek na zavazadla: Před naloděním si vytiskněte zavazadlový štítek z portálu MSC Cruises (např. přes MSC Web Check-in).',
                        '•	Předání zavazadel: Zavazadla označená štítkem odevzdáte personálu při nalodění. Budou doručena přímo do vaší kajuty, obvykle mezi odpolednem a večerem.',
                        '<br>',
                        '<h3>Letenky zakoupené přes MSC Cruises</h3>',
                        'Pokud si zakoupíte letenky prostřednictvím MSC Cruises, získáte:',
                        '•	Transfer: Dopravu z letiště do přístavu a zpět po skončení plavby',
                        '•	Zavazadlo: 20 kg odbaveného zavazadla na osobu v ceně letenky.',
                        '•	Asistenční službu: MSC Assistance je vám k dispozici 24/7 pro případ komplikací s lety.',
                        '<br>',
                        'Doporučení:Ať už cestujete letecky nebo autem, zvažte dostatečnou časovou rezervu pro hladký průběh vaší cesty. Máte-li jakékoliv dotazy ohledně dopravy, neváhejte nás kontaktovat.',
                    ]"
                    imageUrl="/assets/imgs/content/luggage.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                />
            </div>

            <!-- TAB: Restrictions -->
            <div
                id="restrictions"
                class="content-pages__how-it-works__tabs-content"
            >
                <ContentHalves
                    :title="$t('LABELS.restrictions')"
                    :text="[
                        $t('TEXTS.restrictions-text-1'),
                        $t('TEXTS.restrictions-text-2'),
                        $t('TEXTS.restrictions-text-3'),
                        $t('TEXTS.restrictions-text-4'),
                    ]"
                    imageUrl="/assets/imgs/content/restrictions.webp"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="right"
                    waves-location="bottom-left"
                    widerText
                />
            </div>

            <!-- TAB: Fun-on-ship -->
            <div
                id="fun-on-ship"
                class="content-pages__how-it-works__tabs-content"
            >
                <div class="content-pages__how-it-works__two-columns">
                    <div>
                        <h3 class="content-pages__how-it-works__title">
                            {{ $t('LABELS.fun-on-ship') }}
                        </h3>
                        <p>Zábava na palubě – nezapomenutelné zážitky</p>
                        <p>
                            Plavba s MSC je mnohem víc než jen cesta po moři.
                            Každý den je plný aktivit, hudby a atrakcí pro
                            všechny věkové kategorie.
                        </p>
                        <br />

                        <b>Divadelní show a hudba</b>
                        <ul>
                            <li>
                                Profesionální divadelní představení ve stylu
                                Broadway – až 8 produkcí během plavby.
                            </li>
                            <li>
                                Živá hudba různých žánrů – jazz, klasika,
                                latinskoamerická hudba, taneční večery a
                                diskotéky.
                            </li>
                            <li>
                                Rezervace míst na vybraných lodích je snadná
                                přes palubní Wi-Fi nebo recepci.
                            </li>
                        </ul>
                        <br />

                        <b>Denní a noční program</b>
                        <ul>
                            <li>
                                Denní aktivity: Sportovní turnaje, kurzy tance a
                                tematické workshopy.
                            </li>
                            <li>
                                Noční zábava: Karaoke, tematické večírky a
                                taneční soutěže, párty s Dj i večery s živou
                                hudbou.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <b>Rodinná zábava a atrakce</b>
                        <ul>
                            <li>
                                Vodní parky, nejdelší skluzavka na moři
                                (Vertigo), simulátory Formule 1 a 4D kina.
                            </li>
                            <li>
                                Bowlingová dráha a teen zóny s herními
                                konzolemi.
                            </li>
                        </ul>
                        <br />

                        <b>Kasino na palubě</b>
                        <ul>
                            <li>
                                Ruleta, blackjack, poker a výherní automaty.
                            </li>
                            <li>
                                Turnaje s atraktivními cenami a platba kartou i
                                hotově.
                            </li>
                        </ul>
                        <br />

                        <b>Nakupování</b>
                        <ul>
                            <li>
                                Butiky s prémiovým zbožím – šperky, hodinky,
                                oblečení a bezcelní zboží.
                            </li>
                            <li>Otevřeno po celý den během plavby.</li>
                        </ul>
                    </div>
                </div>
                <div class="content-pages__how-it-works__text">
                    <strong>
                        Zažijte na palubě MSC zábavu přesně podle vašich
                        představ – od relaxace až po vzrušující atrakce.
                    </strong>
                </div>
                <GridGallery
                    variant="2"
                    :imgsUrls="
                        [
                            'individual-price.jpg',
                            'individual-price-2.jpg',
                            'dream-program-2.jpg',
                            'team-building.jpg',
                            '9a14d42a-d448-4381-9d49-cfac9bfdfcb4 1.jpg',
                            'dream-program.jpg',
                            'group-of-friends.jpg',
                            'DI_Suite 1.jpg',
                        ].map((file) => `/assets/imgs/content/${file}`)
                    "
                >
                </GridGallery>
            </div>

            <!-- TAB: Excursions -->
            <div
                id="excursions"
                class="content-pages__how-it-works__tabs-content"
            >
                <div class="content-pages__how-it-works__two-columns">
                    <div>
                        <h3 class="content-pages__how-it-works__title">
                            {{ $t('LABELS.excursions') }}
                        </h3>
                        <p>
                            <b>Objevte svět s fakultativními výlety od MSC</b>
                        </p>
                        <p>
                            Chcete svou plavbu obohatit o nezapomenutelné
                            zážitky? Vyberte si z pečlivě sestavené nabídky
                            exkurzí a poznejte destinace bez starostí a
                            zbytečného plánování. Rezervace předem vám zaručí
                            jisté místo a klid, abyste si svůj výlet užili
                            naplno.
                        </p>
                        <br />

                        <b>Proč si vybrat exkurze od MSC?</b>
                        <ul>
                            <li>
                                Široká nabídka pro každého – od rodinných výletů
                                až po dobrodružné expedice.
                            </li>
                            <li>
                                Jisté místo – rezervace předem zaručí, že o svůj
                                vysněný výlet nepřijdete.
                            </li>
                            <li>
                                Bezpečnost a spolehlivost – certifikovaní
                                partneři a zkušení průvodci.
                            </li>
                            <li>
                                Žádné starosti – vše je dokonale zorganizováno.
                            </li>
                            <li>
                                Loď na vás počká – žádný stres z pozdního
                                návratu.
                            </li>
                            <li>
                                Transparentní ceny – jasně stanovené náklady bez
                                skrytých poplatků.
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3 class="content-pages__how-it-works__title">
                            Nejoblíbenější nabídky
                        </h3>
                        <br />
                        <b>Zvýhodněný balíček výletů</b>
                        <p>
                            Užijte si 3 nejoblíbenější výlety během plavby za
                            zvýhodněnou cenu. Bez starostí a čekání – vaše místa
                            jsou rezervována předem. Ideální volba pro ty, kteří
                            chtějí vidět to nejlepší z každé destinace.
                        </p>
                        <br />

                        <b>Zákulisí lodě</b>
                        <p>
                            Exkluzivní možnost prozkoumat běžně nepřístupné
                            části lodi. Nahlédněte do hlavní kuchyně,
                            divadelního zákulisí, prádelny nebo exkluzivních
                            prostor MSC Yacht Clubu.
                        </p>
                        <br />

                        <b>Prémiové exkurze MSC Yacht Club</b>
                        <p>
                            Luxusní a personalizované zážitky. Vydejte se za
                            skrytými poklady, užijte si soukromé plážové pobyty,
                            ochutnejte lokální kulinářské speciality nebo se
                            zúčastněte tematických uměleckých prohlídek.
                        </p>
                        <br />

                        <b>Ekologické výlety PROTECTOURS</b>
                        <p>
                            Objevujte destinace udržitelně a smysluplně. Vydejte
                            se na šnorchlování, pěší túry, kajakování nebo se
                            zapojte do projektů jako čištění pláží či sázení
                            stromů. Spojte zábavu s ohleduplností k přírodě a
                            místní kultuře.
                        </p>
                    </div>
                </div>
                <div class="content-pages__how-it-works__text">
                    <strong>
                        Kapacita exkurzí je omezená. Rezervujte si místo včas a
                        užijte si svou dovolenou bez zbytečných starostí.
                    </strong>
                </div>
            </div>

            <!-- TAB: portal-msc -->
            <div
                id="portal-msc"
                class="content-pages__how-it-works__tabs-content"
            >
                <div class="content-pages__how-it-works__two-columns">
                    <div>
                        <h3 class="content-pages__how-it-works__title">
                            MSC for Me
                        </h3>
                        <p>
                            <b
                                >MSC for Me – chytrá technologie pro vaši
                                plavbu</b
                            >
                        </p>
                        <p>
                            MSC for Me je inovativní aplikace, která vám umožní
                            snadno plánovat aktivity, orientovat se na lodi a
                            rezervovat služby. S touto technologií si svou
                            dovolenou užijete naplno a bez starostí.
                        </p>
                        <br />

                        <h3>Hlavní funkce aplikace</h3>
                        <ul>
                            <li>
                                <b>Rezervace aktivit a služeb</b><br />
                                Rychlá rezervace výletů, restaurací, wellness
                                procedur nebo míst v divadle (na vybraných
                                lodích je rezervace povinná).
                            </li>
                            <li>
                                <b>Interaktivní mapa:</b><br />
                                Umožňuje snadnou navigaci po lodi – najděte svou
                                kajutu, restaurace, bazény a další místa.
                            </li>
                            <li>
                                <b>Denní program:</b><br />
                                Mějte vždy aktuální přehled o plánovaných
                                aktivitách, časech show i večeří.
                            </li>
                            <li>
                                <b>Komunikace s personálem:</b><br />
                                Rychlé řešení dotazů a požadavků přímo z
                                aplikace.
                            </li>
                            <li>
                                <b>Check-in online:</b><br />
                                Umožňuje digitalizovaný a rychlejší proces
                                nalodění bez zbytečného čekání.
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3>Dostupnost aplikace</h3>
                        <ul>
                            <li>
                                Aplikace je zdarma ke stažení v App Store a
                                Google Play (doporučujeme stáhnout předem).
                            </li>
                            <li>
                                Funguje na moderních lodích jako MSC Bellissima,
                                MSC Grandiosa, MSC Seaside a dalších.
                            </li>
                            <li>
                                Na starších lodích (např. MSC Opera, MSC Lirica)
                                aplikace není dostupná.
                            </li>
                        </ul>
                        <br />

                        <h3>MSC for Me náramek</h3>
                        <p>
                            Doplňkem aplikace je chytrý náramek, který nabízí:
                        </p>
                        <ul>
                            <li>Otevírání kajuty: Bez nutnosti nosit kartu.</li>
                            <li>
                                Bezhotovostní platby: Snadné placení na palubě.
                            </li>
                            <li>Rychlý přístup k aktivitám a atrakcím.</li>
                        </ul>
                        <br />
                        <p>
                            Poznámka: Náramek nenahrazuje lodní kartu mimo loď.
                            Pro výlety a návrat na palubu je karta nutná.
                        </p>
                    </div>
                </div>
                <div class="content-pages__how-it-works__text">
                    <strong>
                        MSC for Me přináší pohodlí a jednoduchost – vše, co
                        potřebujete pro klidnou a bezstarostnou plavbu, máte
                        přímo na dosah ruky.
                    </strong>
                </div>
            </div>

            <!-- TAB: msc yacht club -->
            <div id="mscyc" class="content-pages__how-it-works__tabs-content">
                <ContentHalves
                    title="MSC Yacht Club"
                    :text="[
                        '<h3>MSC Yacht Club – luxusní svět uvnitř lodi</h3>',
                        'MSC Yacht Club je exkluzivní koncept na vybraných lodích MSC Cruises, navržený pro hosty, kteří hledají maximální komfort, soukromí a špičkové služby.',
                        'Jedná se o loď v lodi, kde si užijete luxusní prostředí v soukromých prostorách, zatímco máte plný přístup ke všem zařízením a aktivitám velké výletní lodi. Tento jedinečný koncept spojuje intimní atmosféru privátní zóny s rozmanitou nabídkou aktivit a zábavy.',
                        '<br>',
                        '<h3>Co MSC Yacht Club nabízí?</h3>',
                        '<b>Soukromé a exkluzivní prostory:</b>',
                        'Přístup pouze pro hosty Yacht Clubu do oddělených zón, jako je panoramatický salónek Top Sail Lounge, privátní sluneční terasa The One Sun Deck s bazénem a vířivkami a exkluzivní gurmánská restaurace.',
                        '<b>Luxusní ubytování:</b>',
                        '•	Omezený počet prostorných apartmánů na nejlepších místech lodi, které zaručují klid a soukromí.',
                        '•	Župany, pantofle, povlečení z egyptské bavlny, matrace z paměťové pěny a minibar.',
                        '<b>Gastronomický zážitek:</b>',
                        '•	Privátní restaurace s à la carte nabídkou a flexibilními časy večeří.',
                        '•	Nápojový all-inclusive balíček Premium Extra, včetně nápojů z kajutového minibaru.',
                        '<b>Individuální péče:</b>',
                        '•	24hodinová služba komorníka a concierge.',
                        '•	Asistence s vybalováním a balením zavazadel.',
                        '<b>Internetové připojení:</b>',
                        '•	Internetový balíček Browse and Stream pro dvě zařízení, již zahrnutý v ceně plavby.',
                        '<b>Přednostní služby:</b>',
                        '•	Prioritní nalodění a vylodění, včetně návratu z výletů v přístavech.',
                        '•	Rezervace míst v divadle.',
                        '•	Možnost exkluzivních exkurzí přizpůsobených na míru.',
                        '<b>Relaxace:</b>',
                        'Doplňkem aplikace je chytrý náramek, který nabízí:',
                        '•	Neomezený přístup do termální zóny MSC Aurea Spa.',
                        '•	Privátní bazén, vířivky a prostorná sluneční terasa s venkovním barem.',
                        '•	Rychlý přístup k aktivitám a atrakcím.',
                        '<br>',
                        'Všechny výhody MSC Yacht Clubu: https://www.mscbook.com/images/sdl/en-GB/B2B_DOCS_YC-FLYER.pdf',
                        '<b>Kde je MSC Yacht Club k dispozici?</b>',
                        'MSC Yacht Club je dostupný na lodích tříd Fantasia, Meraviglia, Seaside a World Class. Mezi tyto lodě patří například:',
                        '•	MSC Fantasia, MSC Splendida, MSC Divina a MSC Preziosa.',
                        '•	MSC Meraviglia, MSC Bellissima, MSC Virtuosa a MSC Grandiosa.',
                        '•	MSC Seaside, MSC Seaview, MSC Seashore a MSC Seascape..',
                        '•	MSC World Europa.',
                        '<br>',
                        'Tento výjimečný koncept nabízí exkluzivní prostředí, omezený počet kajut a špičkové služby, díky čemuž se každá plavba stává jedinečným zážitkem.',
                        '<br>',
                        'MSC Yacht Club není jen místem na lodi, je to styl cestování, kde každý detail odpovídá vašim představám o dokonalé dovolené.',
                    ]"
                    :button-text="$t('LABELS.order-cruise')"
                    button-url="/vyhledat/"
                    bigger-btn
                    image-side="left"
                    waves-location="bottom-right"
                    widerText
                >
                    <GridGallery
                        variant="1"
                        :imgsUrls="
                            [
                                'individual-price.jpg',
                                'individual-price-2.jpg',
                                'dream-program-2.jpg',
                                'team-building.jpg',
                                '9a14d42a-d448-4381-9d49-cfac9bfdfcb4 1.jpg',
                                'dream-program.jpg',
                                'group-of-friends.jpg',
                                'DI_Suite 1.jpg',
                            ].map((file) => `/assets/imgs/content/${file}`)
                        "
                    >
                    </GridGallery>
                </ContentHalves>
            </div>

            <!-- Additional Info section at bottom -->
            <h2 class="content-pages__heading-2">
                <b class="content-pages__bold">{{
                    $t('LABELS.next-information')
                }}</b>
            </h2>
            <div
                class="features-dash__container features-dash__container--grid"
            >
                <FeaturesDash
                    :heading="$t('LABELS.online-check-in')"
                    :features="[
                        $t('TEXTS.online-check-in-feature-1'),
                        $t('TEXTS.online-check-in-feature-2'),
                        $t('TEXTS.online-check-in-feature-3'),
                        $t('TEXTS.online-check-in-feature-4'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.payments-on-ship')"
                    :features="[
                        $t('TEXTS.payments-on-ship-feature-1'),
                        $t('TEXTS.payments-on-ship-feature-2'),
                        $t('TEXTS.payments-on-ship-feature-3'),
                        $t('TEXTS.payments-on-ship-feature-4'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.internet-connection')"
                    :features="[
                        $t('TEXTS.internet-connection-feature-1'),
                        $t('TEXTS.internet-connection-feature-2'),
                        $t('TEXTS.internet-connection-feature-3'),
                        $t('TEXTS.internet-connection-feature-4'),
                        $t('TEXTS.internet-connection-feature-5'),
                        $t('TEXTS.internet-connection-feature-6'),
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.medical-care')"
                    :features="[
                        'Na každé lodi je nemocnice.',
                        'Ošetření není hrazeno z Evropského zdravotního pojištění.',
                        'Doporučujeme si zřídit cestovní zdravotní pojištění.',
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.travel-documents')"
                    :features="[
                        'Pas nebo občanský průkaz (pro plavby po EU).',
                        'Palubní vstupenka.',
                        'Zdravotní dotazník.',
                        'Víza (v případě vízové povinnosti).',
                    ]"
                    type="info"
                ></FeaturesDash>
                <FeaturesDash
                    :heading="$t('LABELS.cancellation-terms')"
                    :features="[
                        '90 a více dní před odjezdem: 25 % z celkové ceny zájezdu.',
                        '89–60 dní před odjezdem: 40 % z celkové ceny zájezdu.',
                        '59–30 dní před odjezdem: 60 % z celkové ceny zájezdu.',
                        '29–15 dní před odjezdem: 80 % z celkové ceny zájezdu.',
                        '14 dní a méně před odjezdem: 100 % z celkové ceny zájezdu.',
                        '<b>Změna jména pasažéra:</b> Poplatek 50 € za každého pasažéra.',
                        '<b>Změna plavby:</b> Možná do 30 dní před odjezdem, může být zpoplatněna dle aktuálního ceníku MSC Cruises. (Platí pro Fantastica, Aurea a MSC YC)',
                        '<b>Plavby kolem světa:</b> Pro tyto plavby platí specifické storno a změnové podmínky dle zvláštního ceníku.',
                    ]"
                    type="info"
                ></FeaturesDash>
            </div>

            <!-- Fixed FeaturesDash for "Basic price includes" -->
            <div
                class="features-dash__container features-dash__container--grid"
            >
                <!-- BASIC PRICE INCLUDES -->
                <FeaturesDash
                    :subHeading="$t('LABELS.services-basic-price-includes')"
                    :features="[
                        // Ubytování
                        '<b>Ubytování</b>',
                        'Kajuta dle zvolené kategorie (vnitřní, balkónová apod.).',
                        'Každodenní úklid kajuty.',
                        '',
                        // Stravování
                        '<b>Stravování</b>',
                        'Plná penze: Tři hlavní jídla denně, občerstvení a svačiny.',
                        'Voda v bufetové restauraci zdarma.',
                        'U balíčku all-inclusive: Neomezená konzumace alkoholických i nealkoholických nápojů.',
                        '',
                        // Rekreační zařízení
                        '<b>Rekreační zařízení</b>',
                        'Přístup k bazénům, vířivkám, lehátkům, sportovním hřištím a fitness centru.',
                        '',
                        // Zábava na palubě
                        '<b>Zábava na palubě</b>',
                        'Divadelní představení, živá hudba, tematické večery a kulturní akce.',
                        'Sportovní a animační programy pro všechny věkové kategorie.',
                        'Dětské kluby a programy pro mládež.',
                        '',
                        // Služby a poplatky
                        '<b>Služby a poplatky</b>',
                        'Přístavní a servisní poplatky.',
                        'Zavazadlové služby při nalodění a vylodění.',
                        'Asistenční služba v případě potíží (společnost NAUTYY).',
                        'Delegát pro skupinové plavby.',
                        '',
                        // Pojištění
                        '<b>Pojištění</b>',
                        'Pojištění proti úpadku cestovní kanceláře.',
                    ]"
                    :footerText="$t('TEXTS.basic-price-includes-footer')"
                    type="includes"
                ></FeaturesDash>

                <!-- BASIC PRICE EXCLUDES -->
                <FeaturesDash
                    :subHeading="$t('LABELS.additional-services')"
                    :heading="$t('LABELS.basic-price-excludes')"
                    :features="[
                        // Doprava a asistence
                        '<b>Doprava a asistence</b>',
                        'Doprava do místa nalodění a z místa vylodění (pokud není výslovně uvedeno jinak).',
                        'Služby průvodce nebo delegáta (u plaveb, kde nejsou specifikovány).',
                        '',
                        // Nápoje a stravování
                        '<b>Nápoje a stravování</b>',
                        'Alkoholické a nealkoholické nápoje nad rámec zakoupeného balíčku.',
                        'Pokrmy v prémiových a specializovaných restauracích.',
                        'Stravování mimo loď.',
                        '',
                        // Fakultativní služby
                        '<b>Fakultativní služby</b>',
                        'Fakultativní výlety v destinacích včetně transferů v přístavech.',
                        'Wellness a nadstandardní služby: sauna, masáže, lázně, salón krásy, prádelna, minibar, internetové balíčky, lékařské ošetření, fotograf a osobní nákupy.',
                        '',
                        // Poplatky
                        '<b>Poplatky</b>',
                        '15% servisní poplatek u objednávek služeb a nápojů (pokud není zahrnuto v balíčku).',
                        '',
                        // Pojištění a víza
                        '<b>Pojištění a víza</b>',
                        'Cestovní pojištění a pojištění storna zájezdu.',
                        'Pojištění zavazadel.',
                        'Vízum, pokud je požadováno v destinacích.',
                    ]"
                    footerText="V případě zájmu o některé z výše uvedených služeb nás kontaktujte na plavby@nautyy.cz."
                    type="additional"
                    wavesLocation="bottom-right"
                ></FeaturesDash>
            </div>

            <!-- Voyagers Club section -->
            <div
                class="features-dash__container features-dash__container--grid"
            >
                <FeaturesDash
                    heading="MSC Voyagers Club"
                    :subHeading="'Co přináší členství v MSC Voyagers Club?'"
                    :features="[
                        // Main bullet points
                        '<b>Exkluzivní slevy:</b> Získejte výhodnější ceny na plavby a služby.',
                        '<b>Prioritní služby:</b> Přednostní nalodění a rezervace vybraných služeb.',
                        '<b>Pohodlí na palubě:</b> Možnost čerpat slevy na nápojové balíčky, exkurze, wellness služby a další.',
                        '<b>Speciální nabídky:</b> Přístup k časově omezeným akcím a balíčkům.',
                        '<b>Věrnostní body:</b> Čím více plaveb absolvujete, tím více výhod získáte.',
                        '<b>Dlouhá platnost bodů:</b> Stačí absolvovat jednu plavbu každých pět let, abyste zůstali aktivním členem.',
                        'U nás můžete plně využít všech výhod, které MSC Voyagers Club nabízí. Rádi vám pomůžeme uplatnit slevy, využít výhody a zajistíme, aby vaše členství bylo co nejpřínosnější.',
                        '',
                        // Next subheading: Úrovně členství
                        '<b>Úrovně členství</b>',
                        '<b>1. Welcome:</b> Základní členství, které získáte hned při registraci.',
                        '<b>2. Classic:</b> První úroveň s dalšími výhodami po získání bodů.',
                        '<b>3. Silver:</b> Střední úroveň s rozšířenými benefity.',
                        '<b>4. Gold:</b> Prémiová úroveň s významnými výhodami.',
                        '<b>5. Diamond:</b> Čím více plaveb absolvujete, tím více výhod získáte.',
                        '<b>6. Blue Diamond:</b> Nejvyšší a nejluxusnější úroveň plná exkluzivních služeb.',
                        '',
                        // Next subheading: Jak se stát členem?
                        '<b>Jak se stát členem?</b>',
                        '<b>Při rezervaci plavby:</b> Stačí nás kontaktovat, zaregistrujeme vás do programu.',
                        '<b>Na palubě:</b> Požádejte o registraci během vaší plavby.',
                        '<b>Online:</b> Přes portál MSC Cruises.',
                    ]"
                    footerText="MSC Voyagers Club je ideální pro ty, kdo si chtějí užít maximální pohodlí a výhody na každé plavbě. Kontaktujte nás a my zajistíme, že z programu získáte to nejlepší!"
                    type="info"
                    size="fullwidth"
                    :hideLogo="true"
                ></FeaturesDash>
            </div>
        </section>
    </main>

    <ContactBar></ContactBar>
    <Footer></Footer>
</template>

<script>
import { Head } from '@vueuse/head';
import MainNav from '../../components/MainNav';
import ContentHero from '../../components/ContentHero';
import ContentUnderHero from '../../components/ContentUnderHero';
import ContentHalves from '../../components/ContentHalves';
import ContactBar from '../../components/ContactBar';
import GridGallery from '../../components/GridGallery';
import Footer from '../../components/Footer';
import FeaturesDash from '../../components/FeaturesDash.vue';
import { TYPE, LANGUAGE } from '@/env';

export default {
    components: {
        Head,
        MainNav,
        ContentHero,
        ContentUnderHero,
        Footer,
        ContentHalves,
        ContactBar,
        GridGallery,
        FeaturesDash,
    },
    data() {
        return {
            LANGUAGE: LANGUAGE,
            TYPE: TYPE,
            cabinsDescription: [
                this.$t('TEXTS.about-cabin-text-1'),
                this.$t('TEXTS.about-cabin-text-2'),
                this.$t('TEXTS.about-cabin-text-3'),
                `${this.$t('TEXTS.about-cabin-text-4')}<br>
                ${this.$t('LABELS.inner-cabin')}<br>
                ${this.$t('LABELS.sea-view-cabin')}<br>
                ${this.$t('LABELS.terrace-cabin')}<br>
                ${this.$t('LABELS.apartman-cabin')}<br>
                ${this.$t('LABELS.luxury-apartman-cabin')}`,
            ],
        };
    },
    methods: {
        openTab(evt, tabName) {
            // Hide all tab content by default
            const tabcontent = document.getElementsByClassName(
                'content-pages__how-it-works__tabs-content'
            );
            for (let i = 0; i < tabcontent.length; i++) {
                tabcontent[i].classList.remove('active');
            }

            // Remove the active class from all tab links
            const tablinks = document.getElementsByClassName(
                'content-pages__how-it-works__tabs-links'
            );
            for (let i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(
                    ' active',
                    ''
                );
            }

            // Show the current tab content and add the "active" class
            const currentTabContent = document.getElementById(tabName);
            if (currentTabContent) {
                currentTabContent.classList.add('active');
            }

            // If evt is not null, add the "active" class to the clicked tab link
            if (evt) {
                evt.currentTarget.className += ' active';
            } else {
                // If evt is null (e.g., on initial load), set the first tab as active
                tablinks[0].className += ' active';
            }
        },
    },
    mounted() {
        // Open the first tab by default
        this.openTab(null, 'cabins');
    },
};
</script>
