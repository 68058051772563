<template>
    <Head>
        <title>{{ $t('LABELS.cruises-with-delegate') }}</title>
    </Head>

    <header class="header-list header-list--cruises-delegate">
        <Main-nav></Main-nav>
    </header>
    <main class="main main--cruises-delegate">
        <div class="search-filters search-filters--cruises-delegate">
            <div class="search-filters__filters">
                <section class="search-filters__main-filter">
                    <nav class="search-filters__nav">
                        <router-link
                            to="/plavby-s-delegatem/"
                            class="
                                search-filters__nav-item
                                search-filters__nav-item--cruises-delegate
                            "
                        >
                            <!-- <svg
                                class="
                                    search-filters__nav-icon
                                    search-filters__nav-icon--cruises-delegate
                                "
                            >
                                <use
                                    href="/assets/imgs/icons/sprite.svg#cruiser"
                                ></use>
                            </svg> -->
                            <!-- <span class="search-filters__nav-name">{{
                                $t('LABELS.cruiser')
                            }}</span> -->
                        </router-link>
                    </nav>
                    <main
                        class="
                            search-filters__filter-container
                            search-filters__filter-container--cruises-delegate
                        "
                    >
                        <div class="search-filters__filter-wrapper">
                            <svg
                                class="
                                    search-filters__filter-icon
                                    search-filters__filter-icon--cruises-delegate
                                    search-filters__filter-icon--pinpoint
                                "
                            >
                                <use
                                    href="/assets/imgs/icons/sprite.svg#pinpoint"
                                ></use>
                            </svg>
                            <v-select
                                class="
                                    search-filters__iconed-select
                                    search-filters__iconed-select--cruises-delegate
                                "
                                :options="langDestinations"
                                :reduce="(destination) => destination.code"
                                label="langName"
                                v-model="destination"
                                @close="destinationChanged"
                            ></v-select>
                        </div>
                        <div class="search-filters__filter-wrapper">
                            <VueDatePicker
                                v-model="startDate"
                                model-type="yyyy-MM-dd"
                                :clearable="false"
                                :enable-time-picker="false"
                                :min-date="new Date()"
                                class="search-filters__date"
                                :locale="LANGUAGE"
                                :selectText="$t('LABELS.select')"
                                :cancelText="$t('LABELS.cancel')"
                            >
                                <template #input-icon>
                                    <svg
                                        class="
                                            search-filters__filter-icon
                                            search-filters__filter-icon--cruiser
                                            search-filters__filter-icon--calendar
                                        "
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#calendar"
                                        ></use>
                                    </svg>
                                </template>
                            </VueDatePicker>
                        </div>
                        <div class="search-filters__filter-wrapper">
                            <VueDatePicker
                                v-model="endDate"
                                model-type="yyyy-MM-dd"
                                :clearable="false"
                                :enable-time-picker="false"
                                :min-date="new Date()"
                                class="search-filters__date"
                                :locale="LANGUAGE"
                                :selectText="$t('LABELS.select')"
                                :cancelText="$t('LABELS.cancel')"
                            >
                                <template #input-icon>
                                    <svg
                                        class="
                                            search-filters__filter-icon
                                            search-filters__filter-icon--cruiser
                                            search-filters__filter-icon--calendar
                                        "
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#calendar"
                                        ></use>
                                    </svg>
                                </template>
                            </VueDatePicker>
                        </div>
                        <div
                            class="search-filters__filter-wrapper"
                            v-click-outside="hideCrewPopup"
                            @click="showCrewPopup"
                        >
                            <svg
                                class="
                                    search-filters__filter-icon
                                    search-filters__filter-icon--cruises-delegate
                                    search-filters__filter-icon--people
                                "
                            >
                                <use
                                    href="/assets/imgs/icons/sprite.svg#people"
                                ></use>
                            </svg>
                            <input
                                type="text"
                                class="
                                    search-filters__input
                                    search-filters__input--readonly
                                    search-filters__input--clickable
                                "
                                :value="`${adultsNum} ${adultsInflected}, ${childrenNum} ${childrenInflected}`"
                                readonly
                            />
                            <div
                                class="
                                    search-filters__popup
                                    search-filters__popup--crew
                                "
                                v-show="isCrewPopupShown"
                            >
                                <Counter
                                    :min="1"
                                    :initial="adultsNum"
                                    :max="adultsLimit"
                                    :new-val="newAdultsNum"
                                    :title="$t('LABELS.adults-with-age')"
                                    @changed="updateAdultsNum"
                                ></Counter>
                                <Counter
                                    :min="0"
                                    :initial="childrenNum"
                                    :max="childrenLimit"
                                    :new-val="newChildrenNum"
                                    :title="$t('LABELS.children-with-age')"
                                    @changed="updateChildrenNum"
                                ></Counter>
                            </div>
                        </div>
                        <div class="search-filters__filter-wrapper">
                            <svg
                                class="
                                    search-filters__filter-icon
                                    search-filters__filter-icon--cruises-delegate
                                    search-filters__filter-icon--ship
                                "
                            >
                                <use
                                    href="/assets/imgs/icons/ship.svg#SOLID"
                                ></use>
                            </svg>
                            <v-select
                                class="
                                    search-filters__iconed-select
                                    search-filters__iconed-select--cruises-delegate
                                "
                                :options="langCruisers"
                                :reduce="(cruiser) => cruiser.code"
                                label="langName"
                                v-model="shipCode"
                                @close="shipCodeChanged"
                            ></v-select>
                        </div>
                    </main>
                </section>
                <section
                    class="search-filters__optional-filters"
                    :class="{
                        'search-filters__optional-filters--show-advanced':
                            showAdvanced,
                        'search-filters__optional-filters--shown':
                            isShownMobileFiltering,
                    }"
                >
                    <div
                        class="
                            search-filters__modal-header
                            search-filters__modal-header--cruises-delegate
                        "
                    >
                        <svg
                            class="search-filters__modal-close-icon"
                            @click="hideMobileFiltering"
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#simple-arrow"
                            ></use>
                        </svg>
                        <p class="search-filters__modal-header-title">
                            {{ $t('LABELS.filter') }}
                        </p>
                    </div>
                    <div
                        class="
                            search-filters__filter-container
                            search-filters__filter-container--bordered
                        "
                    >
                        <h3
                            class="
                                search-filters__heading
                                search-filters__heading--cruises-delegate
                                search-filters__heading--optional-filters
                            "
                        >
                            {{ $t('LABELS.sail-duration') }}
                        </h3>
                        <v-select
                            class="
                                search-filters__select
                                search-filters__select--cruises-delegate
                            "
                            :options="langDaysMax"
                            :reduce="(dayMax) => dayMax.duration"
                            label="langName"
                            v-model="cruiseDelegateDaysMax"
                        ></v-select>
                    </div>
                    <div
                        class="
                            search-filters__filter-container
                            search-filters__filter-container--bordered
                        "
                    >
                        <h3
                            class="
                                search-filters__heading
                                search-filters__heading--cruises-delegate
                                search-filters__heading--optional-filters
                                search-filters__heading--price
                            "
                        >
                            {{ $t('LABELS.price-for-single') }}
                        </h3>
                        <slider
                            v-model="price"
                            :min="0"
                            :max="priceLimit[$store.currency]"
                            :step="100"
                            :tooltips="false"
                            @change="priceChanged"
                        ></slider>
                        <div
                            class="
                                search-filters__notes
                                search-filters__notes--cruises-delegate
                            "
                        >
                            <span
                                class="
                                    search-filters__price
                                    search-filters__price--min
                                "
                                >{{ formatPrice(price[0]) }}
                                {{
                                    $store.currency === 'czk' ? 'Kč' : '€'
                                }}</span
                            >
                            <span
                                class="
                                    search-filters__price
                                    search-filters__price--max
                                "
                                >{{ formatPrice(price[1])
                                }}{{
                                    price[1] >= priceLimit[$store.currency]
                                        ? '+'
                                        : ''
                                }}
                                {{
                                    $store.currency === 'czk' ? 'Kč' : '€'
                                }}</span
                            >
                        </div>
                    </div>
                    <button
                        class="
                            search-filters__sort-btn
                            search-filters__sort-btn--filtering
                            btn btn--blue btn--standalone
                        "
                        @click="hideMobileFiltering"
                    >
                        {{ $t('LABELS.save') }}
                    </button>
                    <router-link
                        to="/vyhledat/"
                        class="btn btn--orange btn--standalone btn--full-width"
                    >
                        {{ $t('LABELS.cruises-without-delegate') }}
                    </router-link>
                </section>
                <section class="search-filters__banners">
                    <Banner
                        class="search-filters__banner"
                        v-for="banner in banners"
                        :key="banner"
                        :type="banner.desktop.type"
                        :source="banner.desktop.source"
                        :url="banner.url"
                    >
                    </Banner>
                </section>
            </div>

            <div class="search-filters__main">
                <div
                    class="search-filters__sortings"
                    :class="{
                        'search-filters__sortings--show-advanced': showAdvanced,
                        'search-filters__sortings--shown': isShownMobileSorting,
                    }"
                >
                    <div
                        class="
                            search-filters__modal-header
                            search-filters__modal-header--cruises-delegate
                        "
                    >
                        <svg
                            class="search-filters__modal-close-icon"
                            @click="hideMobileSorting"
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#simple-arrow"
                            ></use>
                        </svg>
                        <p class="search-filters__modal-header-title">
                            {{ $t('LABELS.sort') }}
                        </p>
                    </div>
                    <h3
                        class="
                            search-filters__sort-heading
                            search-filters__sort-heading--cruises-delegate
                        "
                    >
                        {{ $t('LABELS.sort-by') }}
                    </h3>
                    <div class="search-filters__sorting-wrapper">
                        <input
                            type="radio"
                            name="sort"
                            id="soon"
                            value="soon"
                            class="search-filters__sorting-radio"
                            v-model="sortBy"
                        />
                        <label
                            for="soon"
                            class="
                                search-filters__sorting-label
                                search-filters__sorting-label--cruises-delegate
                            "
                            >{{ $t('LABELS.soonest-departure') }}</label
                        >
                    </div>
                    <div class="search-filters__sorting-wrapper">
                        <input
                            type="radio"
                            name="sort"
                            id="cheapest"
                            value="cheapest"
                            class="search-filters__sorting-radio"
                            v-model="sortBy"
                        />
                        <label
                            for="cheapest"
                            class="
                                search-filters__sorting-label
                                search-filters__sorting-label--cruises-delegate
                            "
                            >{{ $t('LABELS.from-cheapest') }}</label
                        >
                    </div>
                    <div class="search-filters__sorting-wrapper">
                        <input
                            type="radio"
                            name="sort"
                            id="shortest"
                            value="shortest"
                            class="search-filters__sorting-radio"
                            v-model="sortBy"
                        />
                        <label
                            for="shortest"
                            class="
                                search-filters__sorting-label
                                search-filters__sorting-label--cruises-delegate
                            "
                            >{{ $t('LABELS.shortest') }}</label
                        >
                    </div>
                    <div class="search-filters__sorting-wrapper">
                        <input
                            type="radio"
                            name="sort"
                            id="longest"
                            value="longest"
                            class="search-filters__sorting-radio"
                            v-model="sortBy"
                        />
                        <label
                            for="longest"
                            class="
                                search-filters__sorting-label
                                search-filters__sorting-label--cruises-delegate
                            "
                            >{{ $t('LABELS.longest') }}</label
                        >
                    </div>
                    <hr class="search-filters__sort-hr" />
                    <button
                        class="
                            search-filters__sort-btn
                            btn btn--blue btn--standalone
                        "
                        @click="hideMobileSorting"
                    >
                        {{ $t('LABELS.save') }}
                    </button>
                </div>
                <div
                    class="
                        search-filters__show-advanced
                        search-filters__show-advanced--large
                    "
                    @click="toggleAdvanced"
                >
                    <p class="search-filters__show-advanced-text">
                        {{
                            showAdvanced ? $t('LABELS.hide') : $t('LABELS.show')
                        }}
                        {{ $t('LABELS.advanced-filtering').toLowerCase() }}
                    </p>
                </div>
                <div
                    class="
                        search-filters__show-advanced
                        search-filters__show-advanced--small
                    "
                >
                    <button
                        class="search-filters__show-advanced-option"
                        @click="showMobileSorting"
                    >
                        <svg
                            class="
                                search-filters__show-advanced-icon
                                search-filters__show-advanced-icon--sort
                            "
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#sort"
                            ></use>
                        </svg>
                        {{ $t('LABELS.sort') }}
                    </button>
                    <button
                        class="search-filters__show-advanced-option"
                        @click="showMobileFiltering"
                    >
                        <svg
                            class="
                                search-filters__show-advanced-icon
                                search-filters__show-advanced-icon--filter
                            "
                        >
                            <use
                                href="/assets/imgs/icons/sprite.svg#filter"
                            ></use>
                        </svg>
                        {{ $t('LABELS.filter') }}
                    </button>
                </div>
                <div
                    class="
                        search-filters__content
                        search-filters__content--cruises-delegate
                    "
                >
                    <showcase-item
                        class="search-filters__item"
                        v-for="cruise in searchedCruises"
                        :key="cruise.code"
                        type="cruises-delegate"
                        :car="cruise.transports?.car?.[LANGUAGE]"
                        :plane="cruise.transports?.plane?.[LANGUAGE]"
                        :bus="cruise.transports?.bus?.[LANGUAGE]"
                        :duration="cruise.days"
                        :image-url="cruise.image"
                        :departure-city="cruise.departureName[LANGUAGE]"
                        :destination="cruise.destinationName[LANGUAGE]"
                        :departure-date="cruise.departureDate"
                        :departure-name="cruise.departureName[LANGUAGE]"
                        :arrival-name="cruise.arrivalName[LANGUAGE]"
                        :arrival-date="cruise.arrivalDate"
                        :price="cruise.prices.single[$store.currency]"
                        :code="cruise.code"
                        :cruise-delegate-name="cruise.name[LANGUAGE]"
                        :cruiser-name="cruise.ship_name[LANGUAGE]"
                        :url-suffix="getUrlSuffix()"
                        :notice-text="cruise.label[LANGUAGE]"
                        :default-price-label="
                            $t('LABELS.price-for-one-person-from')
                        "
                        :msc="cruise.ship_name[LANGUAGE].startsWith('MSC')"
                    ></showcase-item>
                    <p
                        class="search-filters__no-results"
                        v-if="!searching && searchedCruises.length === 0"
                    >
                        {{ $t('TEXTS.no-searched-cruises') }}
                    </p>
                </div>
                <Paging
                    :max-page="maxPage"
                    :current-page="currentPage"
                    @changed-page="changePage"
                ></Paging>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <Loading
        :is-loading="isLoading"
        :label="$t('STATUSES.loading-page')"
    ></Loading>
    <Loading
        :is-loading="!isLoading && searching"
        :label="$t('STATUSES.searching-cruises')"
    ></Loading>
</template>

<script>
import Slider from '@vueform/slider';
import moment from 'moment';
import vSelect from 'vue-select';

import ClickOutside from '../../modules/vendor/vue-click-outside';
import Counter from '../../components/Counter.vue';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ShowcaseItem from '../../components/ShowcaseItem.vue';
import Paging from '../../components/Paging';
import { Dictionary } from '../../modules/Dictionary';
import { Api } from '../../modules/Api';
import utilFormatPrice from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import Banner from '../../components/Banner.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { Head } from '@vueuse/head';

import { TYPE, LANGUAGE } from '@/env';

const dict = new Dictionary();

export default {
    url: {
        currentPages: 'page',
    },
    components: {
        Loading,
        Footer,
        MainNav,
        Slider,
        Counter,
        vSelect,
        Paging,
        ShowcaseItem,
        Banner,
        Head,
        VueDatePicker,
    },
    data() {
        return {
            isLoading: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            showAdvanced: false,
            isShownMobileSorting: false,
            isShownMobileFiltering: false,
            priceLimit: {
                czk: 100000,
                eur: 4000,
            },
            price: [0, 0],
            searchedCruises: [],
            adultsNum: 2,
            childrenNum: 0,
            startDate: moment().add(1, 'days').format('YYYY-MM-DD'),
            endDate: moment(
                new Date(new Date().setYear(new Date().getFullYear() + 1))
            ).format('YYYY-MM-DD'),
            isCrewPopupShown: false,
            isDatePopupShown: false,
            destination: 'Kamkoliv',
            destinations: [],
            currentPage: 1,
            maxPage: 1,
            sortBy: 'soon',
            cruiseDelegateDaysMax: 'Libovolná délka',
            newAdultsNum: 0,
            newChildrenNum: 0,
            searching: false,
            canSearch: true,
            itemPriceLabel: 'Cena pro 1. osobu',
            shipCode: 'Jakákoliv',
            cruisers: [],
            banners: [],
        };
    },
    computed: {
        adultsLimit() {
            return 4 - this.childrenNum;
        },
        childrenLimit() {
            return 4 - this.adultsNum;
        },
        adultsInflected() {
            return dict.getWord('adults', this.adultsNum, LANGUAGE);
        },
        childrenInflected() {
            return dict.getWord('children', this.childrenNum);
        },
        langDestinations() {
            const langDestinations = this.destinations.map((destination) => {
                destination.langName = destination.name[LANGUAGE];
                return destination;
            });
            return langDestinations.filter((d) => d.langName !== 'Not Set');
        },
        langCruisers() {
            // Sort alphabetically and map name
            const langCruisers = this.cruisers
                .map((cruiser) => {
                    cruiser.langName = cruiser.name[LANGUAGE];
                    return cruiser;
                })
                .sort((a, b) => a.langName.localeCompare(b.langName));
            return langCruisers;
        },
        langDaysMax() {
            const options = [
                {
                    duration: this.$t('LABELS.any-duration'),
                    name: {
                        cs: this.$t('LABELS.any-duration'),
                        sk: this.$t('LABELS.any-duration'),
                    },
                },
                {
                    duration: 30,
                    name: {
                        cs: this.$t('LABELS.max-30-days'),
                        sk: this.$t('LABELS.max-30-days'),
                    },
                },
                {
                    duration: 60,
                    name: {
                        cs: this.$t('LABELS.max-60-days'),
                        sk: this.$t('LABELS.max-60-days'),
                    },
                },
                {
                    duration: 90,
                    name: {
                        cs: this.$t('LABELS.max-90-days'),
                        sk: this.$t('LABELS.max-90-days'),
                    },
                },
                {
                    duration: 120,
                    name: {
                        cs: this.$t('LABELS.max-120-days'),
                        sk: this.$t('LABELS.max-120-days'),
                    },
                },
            ];

            const langMaxDaysOptions = options.map((option) => {
                option.langName = option.name[LANGUAGE];
                return option;
            });

            return langMaxDaysOptions;
        },
    },
    methods: {
        showMobileSorting() {
            this.isShownMobileSorting = true;
        },
        hideMobileSorting() {
            this.isShownMobileSorting = false;
        },
        showMobileFiltering() {
            this.isShownMobileFiltering = true;
        },
        hideMobileFiltering() {
            this.isShownMobileFiltering = false;
        },
        toggleAdvanced() {
            this.showAdvanced = !this.showAdvanced;
        },
        hideCrewPopup() {
            if (this.isCrewPopupShown === true) {
                this.isCrewPopupShown = false;

                this.search();
                this.saveUrlFilters({ adultsNum: this.adultsNum });
            }
        },
        showCrewPopup() {
            if (this.isCrewPopupShown === false) {
                this.isCrewPopupShown = true;
            }
        },
        hideDatePopup() {
            if (this.isDatePopupShown === true) {
                this.isDatePopupShown = false;
            }
        },
        showDatePopup() {
            if (this.isDatePopupShown === false) {
                this.isDatePopupShown = true;
            }
        },
        updateAdultsNum(num) {
            this.adultsNum = num;
        },
        updateChildrenNum(num) {
            this.childrenNum = num;
        },
        async search(page = 1) {
            if (this.canSearch === false) {
                return null;
            }

            this.searching = true;

            const filters = {
                destination: !this.langDestinations.find(
                    (destination) => destination.code === this.destination
                )?.added
                    ? this.destination
                    : null,
                departureDayFrom: this.startDate,
                departureDayTo: this.endDate,
                adultCount: this.adultsNum,
                childCount: this.childrenNum,
                page,
                orderBy: this.sortBy,
                cruiseDelegateDaysMax: !this.langDaysMax.find(
                    (maxDays) => maxDays.code === this.cruiseDelegateDaysMax
                )?.added
                    ? this.cruiseDelegateDaysMax
                    : null,
                priceMin: this.price[0],
                priceMax:
                    this.price[1] >= this.priceLimit[this.$store.currency]
                        ? null
                        : this.price[1],
                priceCurrency: this.$store.currency,
                shipCode: !this.langCruisers.find(
                    (ship) => ship.code === this.shipCode
                )?.added
                    ? this.shipCode
                    : null,
            };

            const { meta: searchMeta, data: searchData } =
                await this.api.searchCruisesDelegate(filters);

            this.handleSearchResults(searchMeta, searchData);

            this.searching = false;
        },
        handleSearchResults(meta, results) {
            console.log(results);
            this.searchedCruises = results;
            this.maxPage = meta.maxPage;
            this.currentPage = meta.currentPage;
        },
        changePage(newPage) {
            this.currentPage = newPage;
            this.search(this.currentPage);
            window.scrollTo(0, 0);
        },
        fillPrice() {
            this.price = [0, this.priceLimit[this.$store.currency]];
        },
        priceChanged() {
            this.search();
            this.saveUrlFilters({ priceMin: this.price[0] });
            setTimeout(() => {
                this.saveUrlFilters({ priceMax: this.price[1] });
            }, 1);
        },
        destinationChanged() {
            this.search();
            this.saveUrlFilters({ destination: this.destination });
        },
        shipCodeChanged() {
            this.search();
            this.saveUrlFilters({ shipCode: this.shipCode });
        },
        loadUrlFilters() {
            const allowedFilters = [
                'priceMin',
                'priceMax',
                'adultsNum',
                'childrenNum',
                'startDate',
                'endDate',
                'destination',
                'sortBy',
                'cruiseDelegateDaysMax',
                'shipCode',
            ];

            const hash = this.$route.hash.slice(1);

            this.canSearch = false;
            hash.split('&').forEach((param) => {
                const [key, val] = param.split('=');

                if (allowedFilters.indexOf(key) !== -1) {
                    if (key === 'priceMin') {
                        this.price = [val, this.price[1]];
                    } else if (key === 'priceMax') {
                        this.price = [this.price[0], val];
                    } else if (key === 'adultsNum') {
                        if (val > this.adultsLimit) {
                            this.adultsNum = this.adultsLimit;
                            this.newAdultsNum = this.adultsLimit;
                        } else {
                            this.adultsNum = isNaN(Number(val))
                                ? 0
                                : Number(val);
                            this.newAdultsNum = isNaN(Number(val))
                                ? 0
                                : Number(val);
                        }
                    } else if (key === 'childrenNum') {
                        if (val > this.childrenLimit) {
                            this.childrenNum = this.childrenLimit;
                            this.newChildrenNum = this.childrenLimit;
                        } else {
                            this.childrenNum = isNaN(Number(val))
                                ? 0
                                : Number(val);
                            this.newChildrenNum = isNaN(Number(val))
                                ? 0
                                : Number(val);
                        }
                    } else {
                        if (!isNaN(Number(val))) {
                            this[key] = Number(val);
                        } else if (val === 'true' || val === 'false') {
                            this[key] = val === 'true' ? true : false;
                        } else {
                            if (val !== undefined || val?.length === 0) {
                                this[key] = val;
                            }
                        }
                    }
                }
            });
            this.canSearch = true;
        },
        saveUrlFilters(filters) {
            const prevScroll = document.documentElement.scrollTop;
            const hash = this.$route.hash.slice(1);
            const hashParams = {};

            if (hash.length > 0) {
                hash.split('&').forEach((param) => {
                    const [key, val] = param.split('=');
                    hashParams[key] = val;
                });
            }

            Object.keys(filters).forEach((filterKey) => {
                hashParams[filterKey] = filters[filterKey];
            });

            let finalUrlHash = '#';
            Object.keys(hashParams).forEach((hashKey, i) => {
                if (i !== 0) {
                    finalUrlHash += '&';
                }
                if (!hashParams[hashKey]) {
                    finalUrlHash += `${hashKey}`;
                } else {
                    finalUrlHash += `${hashKey}=${hashParams[hashKey]}`;
                }
            });

            if (Object.keys(hashParams).length > 0) {
                this.$router.push({ hash: finalUrlHash });
            }

            setTimeout(() => {
                window.scrollTo(0, prevScroll);
            }, 1);
        },
        formatPrice(price) {
            return utilFormatPrice(price);
        },
        async loadDestinations() {
            this.destinations = await this.api.getDestinations();
        },
        async loadCruisers() {
            this.cruisers = await this.api.getShips();
        },
        setTranslatedDefaults() {
            this.destination = this.$t('LABELS.anywhere');
            this.shipCode = this.$t('LABELS.anywhat');
            this.cruiseDelegateDaysMax = this.$t('LABELS.any-duration');
            this.itemPriceLabel = this.$t('LABELS.price-first-person');
        },
        async loadBanners() {
            this.banners =
                (await this.api.getBanner(TYPE, LANGUAGE, 'search', true)) ||
                this.banners;
        },
        /**
         * Gets the search filter string from the URL hash.
         * @returns {String}
         */
        getSearchFiltersHash() {
            return this.$route.hash.slice(1);
        },
        /**
         * Builds the URL suffix with adults/children and searchFilters.
         * @returns {String}
         */
        getUrlSuffix() {
            const filters = this.getSearchFiltersHash();
            return filters
                ? `searchFilters=${encodeURIComponent(filters)}`
                : '';
        },
    },
    beforeMount() {
        this.setTranslatedDefaults();
        this.loadUrlFilters();
    },
    async mounted() {
        this.isLoading = true;
        this.loadBanners();
        await this.loadDestinations();
        await this.loadCruisers();
        this.fillPrice();
        this.isLoading = false;
        this.search();
    },
    watch: {
        sortBy() {
            this.search();
            this.saveUrlFilters({ sortBy: this.sortBy });
        },
        startDate() {
            this.search();
            this.saveUrlFilters({ startDate: this.startDate });
        },
        endDate() {
            this.search();
            this.saveUrlFilters({ endDate: this.endDate });
        },
        cruiseDelegateDaysMax() {
            this.search();
            this.saveUrlFilters({
                cruiseDelegateDaysMax: this.cruiseDelegateDaysMax,
            });
        },
    },
    directives: {
        ClickOutside,
    },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
