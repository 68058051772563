<template>
    <Head>
        <title>{{ $t('LABELS.order-summary') }}</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main reservation">
        <div class="reservation__inner">
            <Reservation-nav :currentLevel="3"></Reservation-nav>
            <div class="reservation__content reservation__content--summary">
                <h2 class="reservation__heading">
                    {{ $t('LABELS.order-summary') }}
                </h2>
                <div class="summary">
                    <ReservationSide
                        class="summary__summary-side"
                        :destination="overview.destination"
                        :departurePort="overview.departurePortName[LANGUAGE]"
                        :departureDate="overview.departureDate"
                        :arrivalDate="overview.arrivalDate"
                        :duration="overview.duration"
                        :itinerary="overview.itinerary[LANGUAGE]"
                        :adultsNum="5"
                        :childrenNum="5"
                        :crewSummary="true"
                        :fullPrice="overview.fullPrice[$store.currency]"
                        :additionalCost="
                            overview.additionalCost[$store.currency]
                        "
                        :summaryList="overview.summaryList"
                        :summaryPage="true"
                        :cruiserName="workflow.cruise?.ship_name[LANGUAGE]"
                        :portFee="workflow.portFee?.[$store.currency]"
                        :msc="
                            overview.importSource
                                ? overview.importSource === 'msc'
                                : null
                        "
                    ></ReservationSide>
                    <div class="summary__part summary__part--cabin">
                        <div class="summary__mini-part">
                            <h3 class="summary__heading-3">
                                {{ $t('LABELS.travellers-count') }}
                            </h3>
                            <h4 class="summary__heading-4">
                                {{ workflow.guest_count_adult }}x
                                {{ $t('LABELS.adult-with-age') }}
                            </h4>
                            <template v-if="overview.importSource === 'msc'">
                                <h4
                                    v-if="
                                        workflow.age_categories?.children1Num >
                                        0
                                    "
                                    class="summary__heading-4"
                                >
                                    {{ workflow.age_categories.children1Num }}x
                                    {{ $t('LABELS.child') }} (0-1)
                                </h4>
                                <h4
                                    v-if="
                                        workflow.age_categories?.children11Num >
                                        0
                                    "
                                    class="summary__heading-4"
                                >
                                    {{ workflow.age_categories.children11Num }}x
                                    {{ $t('LABELS.child') }} (2-11)
                                </h4>
                                <h4
                                    v-if="
                                        workflow.age_categories?.children17Num >
                                        0
                                    "
                                    class="summary__heading-4"
                                >
                                    {{ workflow.age_categories.children17Num }}x
                                    {{ $t('LABELS.child') }} (12-17)
                                </h4>
                            </template>
                            <template v-else-if="overview.importSource">
                                <h4
                                    v-if="workflow.guest_count_child > 0"
                                    class="summary__heading-4"
                                >
                                    {{ workflow.guest_count_child }}x
                                    {{ $t('LABELS.child-with-age') }}
                                </h4>
                            </template>
                        </div>
                        <hr class="summary__hr" />
                        <div class="summary__mini-part">
                            <h3 class="summary__heading-3">
                                {{ $t('LABELS.accomodation-package') }}
                            </h3>
                            <div
                                class="summary__wysiwyg"
                                v-html="packageNote"
                            ></div>
                        </div>
                        <hr
                            class="summary__hr"
                            v-if="flights[workflow.flight]"
                        />
                        <div
                            class="summary__mini-part"
                            v-if="flights[workflow.flight]"
                        >
                            <h3 class="summary__heading-3">
                                {{ $t('LABELS.transport') }}
                            </h3>
                            <h4 class="summary__heading-4">
                                {{ $t('LABELS.air-ticket') }}
                            </h4>
                            <p class="summary__text">
                                {{
                                    $t('TEXTS.air-ticket-note-with-city', {
                                        city: flights[workflow.flight],
                                    })
                                }}
                            </p>
                        </div>
                        <hr class="summary__hr show-tablet" />
                        <div
                            class="summary__mini-part summary__mini-part--cabin"
                        >
                            <h3 class="summary__heading-3">
                                {{ $t('LABELS.cabin') }}
                            </h3>
                            <img
                                :src="overview.cabinImageUrl"
                                class="summary__cabin-img"
                            />
                            <p class="summary__sub-heading">
                                {{ overview.cabinLocation[LANGUAGE] }}
                            </p>
                            <h4 class="summary__heading-4">
                                {{ overview.cabinName[LANGUAGE] }}
                            </h4>
                            <p class="summary__cabin-info">
                                {{ $t('LABELS.cabin-type') }}:
                                <strong>
                                    {{ cabinType }}
                                </strong>
                            </p>
                            <p class="summary__cabin-info">
                                {{ $t('LABELS.cabin-number') }}:
                                <strong>{{ workflow.cabin_number }}</strong>
                            </p>
                            <p class="summary__cabin-info">
                                {{ $t('LABELS.accomodation-package') }}:
                                <strong>{{ workflow.fare_code }}</strong>
                            </p>
                        </div>
                    </div>
                    <div class="summary__part">
                        <router-link
                            :to="`/rezervace/${overview.cruiseCode}/platba/`"
                            class="btn btn--full-width btn--orange"
                            >{{ $t('LABELS.to-payment-and-reservation') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <LoadingManager ref="loadingManager"></LoadingManager>
</template>

<script>
import camelCase from 'camelcase';

import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import formatPriceUtil from '../../utilities/formatPrice';
import { Api } from '../../modules/Api';
import LoadingManager from '@/components/LoadingManager.vue';

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';

import * as _ from 'lodash';

import cabinsImages from '@/storage/cabins-imgs.json';
import cabinsNames from '@/storage/cabins-names.json';

export default {
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            workflow: {},
            flights: {},
            overview: {
                cruiseCode: this.$route.params.cruise_code,
                destination: '',
                departurePortName: {},
                departureDate: '',
                arrivalDate: '',
                duration: 0,
                cabinName: { cs: '', sk: '' },
                cabinLocation: { cs: '', sk: '' },
                itinerary: {
                    cs: [],
                    sk: [],
                },
                adultsNum: 0,
                childrenNum: 0,
                fullPrice: {
                    czk: {},
                    eur: {},
                },
                summaryList: [],
                additionalCost: {
                    czk: {},
                    eur: {},
                },
            },
            packageNote: '',
            cabinImageUrl: '',
            cabinType: '',
        };
    },
    methods: {
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        async handleWorkflow() {
            const workflows = localStorage.getItem('workflows')
                ? JSON.parse(localStorage.getItem('workflows'))
                : {};
            const currentWorkflowId = workflows[this.overview.cruiseCode]
                ? workflows[this.overview.cruiseCode]
                : this.$router.push({
                      path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                  });

            this.overview.workflowId = currentWorkflowId;
        },
        async loadSummary() {
            await this.api.updateBooking(this.overview.workflowId, {
                currency: this.$store.currency,
                supplementList: [],
            });
            const { workflow, cruise, flights } = await this.api.getSummary(
                this.overview.workflowId
            );

            if (!workflow || !cruise) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error',
                });
                return;
            }

            this.workflow = workflow;
            this.flights = flights;

            Object.keys(cruise).map((key) => {
                if (key === 'days') {
                    this.overview.duration = cruise[key];
                } else {
                    this.overview[camelCase(key)] = cruise[key];
                }
            });

            this.cabinType =
                cabinsNames[
                    this.overview.importSource === 'msc' ? 'MSC' : 'COSTA'
                ]?.[
                    workflow.category_code?.split(';')[1] ||
                        workflow.category_code?.split(';')[0]
                ]?.[this.LANGUAGE] ||
                workflow.category_code?.split(';')[1] ||
                workflow.category_code?.split(';')[0];
        },
        async loadPackageNote() {
            console.debug(
                `Loading snippet: ${this.workflow.fare_code?.toLowerCase()}-info`
            );
            this.packageNote = (
                await this.api.getSnippet(
                    `${this.workflow.fare_code?.toLowerCase()}-info`
                )
            )[LANGUAGE];
        },
        async handleHoldCabin() {
            let resp = await this.api.holdCabin(this.overview.workflowId);
            if (resp.data.error) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error',
                });
            }
        },
        async getCabinInfo() {},
        findCabinImageUrl() {
            const shipCode = this.workflow?.cruise.ship_code;
            const cabinCode = this.workflow?.category_code;
            const cabin = _.find(
                this.workflow?.categories,
                ({ code }) => code === cabinCode
            );

            const imageList =
                cabinsImages[shipCode]?.[
                    cabinCode.split(';')?.[1] || cabinCode
                ];
            const image = _.first(imageList);

            if (image) {
                this.overview.cabinImageUrl = `/assets/imgs/cabins/${shipCode}/${
                    cabinCode.split(';')?.[1] || cabinCode
                }/${image}`;
            }

            if (cabin?.name[LANGUAGE]) {
                this.overview.cabinName = cabin?.name[LANGUAGE];
            }
            if (cabin?.cabinLocation) {
                this.overview.cabinLocation = cabin?.cabinLocation;
            }
        },
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationSide,
        Head,
        LoadingManager,
    },
    computed: {},
    async mounted() {
        this.$refs.loadingManager.start(4);

        const workflowPromise = this.$refs.loadingManager.addTask(
            this.$t('STATUSES.loading-workflow'),
            0,
            this.handleWorkflow()
        );
        const holdCabinPromise = this.$refs.loadingManager.addTask(
            this.$t('STATUSES.holding-cabin'),
            1,
            workflowPromise.then(() => this.handleHoldCabin())
        );
        const summaryPromise = this.$refs.loadingManager.addTask(
            this.$t('STATUSES.loading-order-summary'),
            2,
            workflowPromise.then(() => this.loadSummary())
        );
        const packageNotePromise = this.$refs.loadingManager.addTask(
            this.$t('STATUSES.loading-package-note'),
            3,
            summaryPromise.then(() => this.loadPackageNote())
        );

        await Promise.all([
            workflowPromise,
            holdCabinPromise,
            summaryPromise,
            packageNotePromise,
        ]);
        this.findCabinImageUrl();

        this.$refs.loadingManager.stop();
    },
};
</script>
