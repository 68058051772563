<template>
    <Head>
        <title>{{ $t('TITLES.homepage') }}</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main reservation">
        <div class="reservation__inner">
            <Notification
                v-if="error.show"
                :text="error.text[LANGUAGE]"
                @closed="closeNotification"
            ></Notification>
            <Reservation-nav v-else :current-level="2"></Reservation-nav>
            <div class="reservation__content">
                <div class="reservation__column">
                    <h2 class="reservation__heading">
                        {{ $t('LABELS.transport') }}
                    </h2>
                    <FlightsDash
                        :airports="flights"
                        @updated="updateFlight"
                    ></FlightsDash>
                    <button
                        class="btn btn--orange btn--full-width"
                        @click="chooseFlight"
                    >
                        {{ $t('LABELS.continue-to-summary') }}
                    </button>
                </div>
                <div class="reservation__column">
                    <h2 class="reservation__heading">
                        {{ $t('LABELS.trip-info') }}
                    </h2>
                    <ReservationSide
                        :destination="overview.destination"
                        :departurePort="overview.departurePortName[LANGUAGE]"
                        :departureDate="overview.departureDate"
                        :arrivalDate="overview.arrivalDate"
                        :duration="overview.duration"
                        :itinerary="overview.itinerary[LANGUAGE]"
                        :crewSummary="true"
                        :summaryList="overview.summaryList"
                        :cruiserName="workflow.cruise?.ship_name[LANGUAGE]"
                        :msc="
                            overview.importSource
                                ? overview.importSource === 'msc'
                                : null
                        "
                    ></ReservationSide>
                </div>
            </div>
        </div>
    </main>
    <Footer></Footer>
    <LoadingManager ref="loadingManager"></LoadingManager>
</template>

<script>
import camelCase from 'camelcase';

import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import { Api } from '../../modules/Api';
import Notification from '../../components/Notification.vue';
import LoadingManager from '@/components/LoadingManager.vue';

import { Head } from '@vueuse/head';

import FlightsDash from '../../components/FlightsDash.vue';

import { LANGUAGE } from '@/env';

export default {
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationSide,
        FlightsDash,
        Notification,
        Head,
        LoadingManager,
    },
    data() {
        return {
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            overview: {
                cruiseCode: this.$route.params.cruise_code,
                destination: '',
                departurePortName: {},
                departureDate: '',
                arrivalDate: '',
                duration: 0,
                itinerary: {
                    cs: [],
                    sk: [],
                },
                adultsNum: 0,
                childrenNum: 0,
                summaryList: [],
                additionalCost: {
                    czk: {},
                    eur: {},
                },
            },
            flights: [
                {
                    code: null,
                    added: true,
                    text: this.$t('LABELS.without-air-ticket'),
                },
            ],
            flight: {
                code: null,
                added: true,
                text: this.$t('LABELS.without-air-ticket'),
            },
            workflow: {},
            error: {
                show: false,
                text: {
                    cs: [],
                    sk: [],
                },
            },
        };
    },
    methods: {
        async handleWorkflow() {
            const workflows = localStorage.getItem('workflows')
                ? JSON.parse(localStorage.getItem('workflows'))
                : {};
            const currentWorkflowId = workflows[this.overview.cruiseCode]
                ? workflows[this.overview.cruiseCode]
                : this.$router.push({
                      path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                  });

            this.overview.workflowId = currentWorkflowId;
        },
        async loadFlights() {
            const { flights, workflow, cruise } = await this.api.getFlights(
                this.overview.workflowId
            );

            if (!workflow || !cruise) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error',
                });
            }

            if (
                workflow.components.find(
                    (flight) =>
                        flight.type === 'Flight' &&
                        flight.direction === 'Both' &&
                        flight.mandatory
                )
            ) {
                this.flights = [];
            }

            const savedAirport = flights.find(
                (flight) => workflow.flight === flight.code
            );
            if (savedAirport) {
                savedAirport.selected = true;
            }

            this.flights = [...this.flights, ...flights];
            this.workflow = workflow;

            Object.keys(cruise).map((key) => {
                if (key === 'days') {
                    this.overview.duration = cruise[key];
                } else {
                    this.overview[camelCase(key)] = cruise[key];
                }
            });
        },
        async chooseFlight() {
            if (
                !this.flights.find(
                    (flight) => flight.code === this.flight?.code
                )
            ) {
                this.error.text = {
                    cs: [this.$t('ERRORS.not-chosen-air-ticket')],
                    sk: [this.$t('ERRORS.not-chosen-air-ticket')],
                };

                this.error.show = true;
                window.scrollTo(0, 0);
                return;
            }

            this.$refs.loadingManager.start(1);
            await this.$refs.loadingManager.addTask(
                this.$t('STATUSES.saving-transport'),
                0,
                this.api.setFlight(this.overview.workflowId, this.flight.code)
            );
            this.$refs.loadingManager.stop();

            this.$router.push({
                path: `/rezervace/${this.overview.cruiseCode}/shrnuti/`,
            });
        },
        updateFlight(newFlightCode) {
            this.flight = this.flights.find(
                (flight) => flight.code === newFlightCode
            );
        },
        closeNotification() {
            this.error.show = false;
        },
        handleErrors() {
            const hash = this.$route.hash;
            if (hash.includes('not-chosen')) {
                let newHash = hash
                    .replace('&generic-error', '')
                    .replace('generic-error&', '')
                    .replace('generic-error', '');
                if (newHash.length <= 1) {
                    newHash = '';
                }

                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.not-chosen-air-ticket')],
                    sk: [this.$t('ERRORS.not-chosen-air-ticket')],
                };

                this.$router.push({ hash: newHash });
            }
        },
    },
    async mounted() {
        this.$refs.loadingManager.start(2);
        const workFlowPromise = this.$refs.loadingManager.addTask(
            this.$t('STATUSES.loading-workflow'),
            0,
            this.handleWorkflow()
        );
        const flightsPromise = this.$refs.loadingManager.addTask(
            this.$t('STATUSES.loading-flights'),
            1,
            workFlowPromise.then(() => this.loadFlights())
        );
        await Promise.all([workFlowPromise, flightsPromise]);
        this.$refs.loadingManager.stop();

        if (this.flights.length < 2) {
            this.$router.push(
                `/rezervace/${this.overview.cruiseCode}/shrnuti/`
            );
        }

        this.handleErrors();
    },
};
</script>
