<template>
    <Head>
        <title>{{ $t('TITLES.homepage') }}</title>
    </Head>

    <header class="header-detail">
        <Main-nav></Main-nav>
    </header>
    <main class="main">
        <p>
            {{ $t('TEXTS.only-registered-can-continue') }}<br />{{
                $t('TEXTS.register-to-finished-order')
            }}
        </p>
        <div class="buttons">
            <router-link
                :to="`/registrace/`"
                class="btn btn--orange btn--standalone"
                >{{ $t('LABELS.register-account') }}</router-link
            >
            <router-link
                to="/vyhledat/"
                class="btn btn--blue btn--standalone"
                >{{ $t('LABELS.back-to-search') }}</router-link
            >
        </div>
    </main>
    <Footer></Footer>
</template>

<script>
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';

import { Head } from '@vueuse/head';

export default {
    components: {
        Footer,
        MainNav,
        Head,
    },
    props: ['cruise_code'],
    data() {
        return {};
    },
    computed: {},
    methods: {},
    mounted() {
        if (this.$store.user.isLogged) {
            this.$router.push(
                `/rezervace/${this.cruise_code}/kajuta-a-ubytovaci-balicek/`
            );
            return;
        }
    },
    watch: {},
};
</script>
<style scoped>
.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 4rem;
}
.main > p {
    text-align: center;
    font-size: 2.6rem;
    margin-bottom: 4rem;
}

.main .buttons {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 2rem;
}
@media screen and (max-width: 32em) {
    .main .buttons {
        grid-template-columns: 1fr;
    }
}
</style>
