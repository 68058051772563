<template>
    <Head>
        <title>{{ $t('TITLES.homepage') }}</title>
    </Head>

    <div :class="{ 'no-scrolling': showLoginPopup }">
        <header class="header-detail">
            <Main-nav></Main-nav>
        </header>
        <main class="main reservation">
            <div class="reservation__inner">
                <Notification
                    :text="error.text[LANGUAGE]"
                    v-if="error.show"
                    :initConfig="{ small: true }"
                    @closed="closeNotification"
                ></Notification>
                <Reservation-nav v-else :currentLevel="4"></Reservation-nav>
                <div class="reservation__content">
                    <form class="reservation__column" @submit.prevent="">
                        <h2 class="reservation__heading">
                            {{ $t('LABELS.info-about-trip-crew') }}
                        </h2>
                        <div
                            class="person-form"
                            v-if="passengersAdult.length > 0"
                        >
                            <h3 class="person-form__heading">
                                {{ $t('LABELS.adult') }} 1 ({{
                                    $t('LABELS.contact-person').toLowerCase()
                                }})
                            </h3>
                            <p class="person-form__note">
                                {{ $t('TEXTS.no-diacritics') }}
                            </p>
                            <div class="person-form__inputs">
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="first-name-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.first-name') }}</label
                                    >
                                    <input
                                        type="text"
                                        id="first-name-2"
                                        class="person-form__input"
                                        v-model="passengersAdult[0].firstName"
                                        required
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="last-name-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.last-name') }}</label
                                    >
                                    <input
                                        type="text"
                                        id="last-name-2"
                                        class="person-form__input"
                                        v-model="passengersAdult[0].lastName"
                                        required
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="gender-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.sex') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="genderOptions"
                                        :reduce="(gender) => gender.code"
                                        label="text"
                                        v-model="passengersAdult[0].sex"
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="nationality-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.nationality') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="nationalityOptions"
                                        :reduce="
                                            (nationality) => nationality.code
                                        "
                                        label="text"
                                        v-model="passengersAdult[0].nationality"
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="date-of-birth-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.date-of-birth') }}</label
                                    >
                                    <input
                                        type="date"
                                        id="date-of-birth-2"
                                        class="person-form__input"
                                        v-model="passengersAdult[0].birthDate"
                                        required
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="email-1"
                                        class="person-form__label"
                                        >{{ $t('LABELS.email-address') }}</label
                                    >
                                    <input
                                        type="email"
                                        id="email-1"
                                        class="person-form__input"
                                        v-model="passengersAdult[0].email"
                                        required
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="country-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.country') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="countryOptions"
                                        :reduce="(country) => country.code"
                                        label="text"
                                        v-model="passengersAdult[0].countryCode"
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="zip-1"
                                        class="person-form__label"
                                        >{{ $t('LABELS.zip') }}</label
                                    >
                                    <input
                                        type="number"
                                        id="zip-1"
                                        class="person-form__input"
                                        v-model="passengersAdult[0].zip"
                                        required
                                    />
                                </div>
                                <div class="person-form__phone-part">
                                    <div class="person-form__input-wrapper">
                                        <label
                                            for="phone-prefix-1"
                                            class="person-form__label"
                                            >{{
                                                $t('LABELS.phone-prefix')
                                            }}</label
                                        >
                                        <input
                                            type="tel"
                                            id="phone-prefix-1"
                                            class="person-form__input person-form__input--small"
                                            v-model="passengersAdult[0].prefix"
                                            required
                                        />
                                    </div>
                                    <div class="person-form__input-wrapper">
                                        <label
                                            for="phone-1"
                                            class="person-form__label"
                                            >{{
                                                $t('LABELS.phone-number')
                                            }}</label
                                        >
                                        <input
                                            type="tel"
                                            id="phone-1"
                                            class="person-form__input"
                                            v-model="passengersAdult[0].phone"
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="person-form"
                            v-for="i in workflow.guest_count_adult
                                ? workflow.guest_count_adult - 1
                                : 0"
                            :key="i"
                        >
                            <h3 class="person-form__heading">
                                {{ $t('LABELS.adult') }} {{ i + 1 }}
                            </h3>
                            <p class="person-form__note">
                                {{ $t('TEXTS.no-diacritics') }}
                            </p>
                            <div class="person-form__inputs">
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="first-name-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.first-name') }}</label
                                    >
                                    <input
                                        type="text"
                                        id="first-name-2"
                                        class="person-form__input"
                                        v-model="passengersAdult[i].firstName"
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="last-name-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.last-name') }}</label
                                    >
                                    <input
                                        type="text"
                                        id="last-name-2"
                                        class="person-form__input"
                                        v-model="passengersAdult[i].lastName"
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="gender-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.sex') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="genderOptions"
                                        :reduce="(gender) => gender.code"
                                        label="text"
                                        v-model="passengersAdult[i].sex"
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="nationality-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.nationality') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="nationalityOptions"
                                        :reduce="
                                            (nationality) => nationality.code
                                        "
                                        label="text"
                                        v-model="passengersAdult[i].nationality"
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="date-of-birth-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.date-of-birth') }}</label
                                    >
                                    <input
                                        type="date"
                                        id="date-of-birth-2"
                                        class="person-form__input"
                                        v-model="passengersAdult[i].birthDate"
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            class="person-form"
                            v-for="i in workflow.guest_count_child
                                ? workflow.guest_count_child
                                : 0"
                            :key="i"
                        >
                            <h3 class="person-form__heading">
                                {{ $t('LABELS.child') }} {{ i }}
                            </h3>
                            <p class="person-form__note">
                                {{ $t('TEXTS.no-diacritics') }}
                            </p>
                            <div class="person-form__inputs">
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="first-name-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.first-name') }}</label
                                    >
                                    <input
                                        type="text"
                                        id="first-name-2"
                                        class="person-form__input"
                                        v-model="
                                            passengersChild[i - 1].firstName
                                        "
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="last-name-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.last-name') }}</label
                                    >
                                    <input
                                        type="text"
                                        id="last-name-2"
                                        class="person-form__input"
                                        v-model="
                                            passengersChild[i - 1].lastName
                                        "
                                    />
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="gender-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.sex') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="genderOptions"
                                        :reduce="(gender) => gender.code"
                                        label="text"
                                        v-model="passengersChild[i - 1].sex"
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="nationality-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.nationality') }}</label
                                    >
                                    <v-select
                                        class="person-form__select"
                                        :options="nationalityOptions"
                                        :reduce="
                                            (nationality) => nationality.code
                                        "
                                        label="text"
                                        v-model="
                                            passengersChild[i - 1].nationality
                                        "
                                    ></v-select>
                                </div>
                                <div class="person-form__input-wrapper">
                                    <label
                                        for="date-of-birth-2"
                                        class="person-form__label"
                                        >{{ $t('LABELS.date-of-birth') }}</label
                                    >
                                    <input
                                        type="date"
                                        id="date-of-birth-2"
                                        class="person-form__input"
                                        v-model="
                                            passengersChild[i - 1].birthDate
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <h2 class="payment-box__heading">
                            {{ $t('LABELS.payment') }}
                        </h2>
                        <div class="small-offer">
                            <div class="small-offer__part">
                                <h3 class="small-offer__heading">
                                    {{ $t('LABELS.pay-everything-once') }}
                                </h3>
                                <p class="small-offer__text">
                                    {{ $t('TEXTS.pay-note') }}
                                </p>
                            </div>
                            <div class="payment-box__content">
                                <p class="payment-box__price">
                                    {{
                                        formatPrice(finalPrice?.amount_decimal)
                                    }}
                                    {{ finalPrice?.currency }}
                                </p>
                                <p class="payment-box__price__note">
                                    {{ $t('TEXTS.price-to-pay') }}
                                </p>
                                <button
                                    class="payment-box__pay-btn"
                                    @click="makeReservation('full', true)"
                                >
                                    <img
                                        class="payment-box__pay-icon"
                                        src="/assets/imgs/icons/circle-check.svg"
                                    />
                                    {{ $t('TEXTS.pay-and-finish-order') }}
                                </button>
                                <div class="payment-box__methods-box">
                                    <svg
                                        class="payment-box__pay-method-icon payment-box__pay-method-icon--visa"
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#visa"
                                        ></use>
                                    </svg>
                                    <svg
                                        class="payment-box__pay-method-icon payment-box__pay-method-icon--mastercard"
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#mastercard"
                                        ></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="small-offer" v-if="isDepositAvailable">
                            <div class="small-offer__part">
                                <h3 class="small-offer__heading">
                                    {{ $t('LABELS.pay-25-deposit') }}
                                </h3>
                                <p class="small-offer__text">
                                    {{ $t('TEXTS.pay-25-deposit-note') }}
                                </p>
                            </div>
                            <div class="payment-box__content">
                                <p class="payment-box__price">
                                    {{
                                        formatPrice(
                                            finalPrice?.amount_decimal / 4
                                        )
                                    }}
                                    {{ finalPrice?.currency }}
                                </p>
                                <p class="payment-box__price__note">
                                    {{ $t('TEXTS.price-deposit-to-pay') }}
                                </p>
                                <button
                                    class="payment-box__pay-btn"
                                    @click="makeReservation('deposit', true)"
                                >
                                    <img
                                        class="payment-box__pay-icon"
                                        src="/assets/imgs/icons/circle-check.svg"
                                    />
                                    {{ $t('TEXTS.pay-and-finish-order') }}
                                </button>
                                <div class="payment-box__methods-box">
                                    <svg
                                        class="payment-box__pay-method-icon payment-box__pay-method-icon--visa"
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#visa"
                                        ></use>
                                    </svg>
                                    <svg
                                        class="payment-box__pay-method-icon payment-box__pay-method-icon--mastercard"
                                    >
                                        <use
                                            href="/assets/imgs/icons/sprite.svg#mastercard"
                                        ></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="checkbox-agree">
                            <input
                                type="checkbox"
                                class="checkbox-agree__checkbox"
                                id="gdpr-agree"
                                v-model="allowGDPR"
                            />
                            <label
                                for="gdpr-agree"
                                class="checkbox-agree__label"
                                >{{ $t('TEXTS.agree-with-gdpr') }}
                                <a
                                    href="https://info.nautyy.cz/ochrana-soukromi/"
                                    class="checkbox-agree__link"
                                    target="_blank"
                                    >{{ $t('TEXTS.GDPR') }}</a
                                ></label
                            >
                        </div>
                    </form>
                    <div class="reservation__column">
                        <h2 class="reservation__heading hide-mobile--large">
                            {{ $t('LABELS.trip-info') }}
                        </h2>
                        <ReservationSide
                            class="hide-mobile--large"
                            :destination="overview.destination"
                            :departurePort="
                                overview.departurePortName[LANGUAGE]
                            "
                            :departureDate="overview.departureDate"
                            :arrivalDate="overview.arrivalDate"
                            :duration="overview.duration"
                            :itinerary="overview.itinerary[LANGUAGE]"
                            :fullPrice="overview.fullPrice[$store.currency]"
                            :adultsNum="5"
                            :childrenNum="5"
                            :crewSummary="true"
                            :additionalCost="
                                overview.additionalCost[$store.currency]
                            "
                            :summaryList="overview.finalSummaryList"
                            :cruiserName="workflow.cruise?.ship_name[LANGUAGE]"
                            :promo="promoInfo[$store.currency]"
                            :promoApplied="promoApplied"
                            :msc="
                                overview.importSource
                                    ? overview.importSource === 'msc'
                                    : null
                            "
                        ></ReservationSide>
                        <h2
                            class="reservation__heading reservation__heading--yacht"
                        >
                            {{ $t('LABELS.promo-code') }}
                        </h2>
                        <div class="person-form">
                            <p
                                class="reservation__promo-text"
                                v-if="promoApplied"
                            >
                                {{
                                    $t('STATUSES.promo-successfully-applied', {
                                        priceText: `${formatPrice(
                                            promoInfo[$store.currency]
                                                .amount_decimal
                                        )} ${
                                            promoInfo[$store.currency].currency
                                        }`,
                                    })
                                }}
                            </p>
                            <p
                                class="reservation__promo-text"
                                v-else-if="promoOnce"
                            >
                                {{ $t('ERRORS.bad-promo-code') }}
                            </p>
                            <input
                                type="text"
                                name="promo"
                                v-model="promoCode"
                                class="person-form__input person-form__input--fullwidth person-form__input--promo"
                                maxlength="5"
                            />
                            <button
                                class="reservation__promo-btn btn btn--orange btn--standalone"
                                @click="applyPromo"
                            >
                                {{ $t('LABELS.apply-promo-code') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <Footer></Footer>
        <Loading
            :is-loading="isLoading"
            :label="$t('STATUSES.loading-reservation-payment')"
        ></Loading>
        <Loading
            :is-loading="!isLoading && applyingPromo"
            :label="$t('STATUSES.applying-promo')"
        ></Loading>
        <Loading
            :is-loading="creatingReservation"
            :label="$t('STATUSES.creating-reservation')"
        ></Loading>
        <LoginPage
            v-if="showLoginPopup"
            :is-popup="true"
            :full-window="true"
            extra-message="Před dokončením rezervace se prosím přihlašte."
            @logged="checkLoggedUser"
        ></LoginPage>
    </div>
</template>

<script>
import camelCase from 'camelcase';
import vSelect from 'vue-select';

import { upperCaseFirst } from 'upper-case-first';
import Footer from '../../components/Footer';
import MainNav from '../../components/MainNav';
import ReservationNav from '../../components/ReservationNav.vue';
import ReservationSide from '../../components/ReservationSide.vue';
import { Api } from '../../modules/Api';
import Notification from '../../components/Notification.vue';
import formatPriceUtil from '../../utilities/formatPrice';
import Loading from '../../components/Loading.vue';
import { getPhonePrefix } from '@/utilities/location';

import LoginPage from '../LoginPage.vue';

import { Head } from '@vueuse/head';

import { LANGUAGE } from '@/env';

export default {
    data() {
        return {
            showLoginPopup: false,
            isLoading: false,
            applyingPromo: false,
            creatingReservation: false,
            api: new Api(this.$t),
            LANGUAGE: LANGUAGE,
            overview: {
                cruiseCode: this.$route.params.cruise_code,
                destination: '',
                departurePortName: {},
                departureDate: '',
                arrivalDate: '',
                duration: 0,
                itinerary: {
                    cs: [],
                    sk: [],
                },
                adultsNum: 2,
                childrenNum: 1,
                fullPrice: {
                    czk: {},
                    eur: {},
                },
                additionalCost: {
                    czk: {},
                    eur: {},
                },
                summaryList: [],
                finalSummaryList: [],
                transportIncluded: true,
            },
            passengersAdult: [],
            passengersChild: [],
            workflow: {},
            error: {
                show: false,
                text: {
                    cs: [],
                    sk: [],
                },
            },
            allowGDPR: false,
            genderOptions: [
                {
                    code: 'Male',
                    text: this.$t('LABELS.male'),
                },
                {
                    code: 'Female',
                    text: this.$t('LABELS.female'),
                },
                {
                    code: 'NotAvailable',
                    text: this.$t('LABELS.sex-unspecified'),
                },
            ],
            nationalityOptions: [
                {
                    code: 'cs',
                    text: this.$t('LABELS.czech-nationality'),
                },
                {
                    code: 'sk',
                    text: this.$t('LABELS.slovak-nationality'),
                },
            ],
            countryOptions: [],
            promoCode: '',
            promoInfo: { czk: {}, eur: {} },
            promoApplied: false,
            promoOnce: false,
        };
    },
    methods: {
        checkLoggedUser() {
            this.showLoginPopup = !this.$store.user.isLogged;
        },
        formatPrice(price) {
            return formatPriceUtil(price);
        },
        async applyPromo() {
            this.applyingPromo = true;
            const promoInfo = await this.api.getPromoInfo(this.promoCode);
            this.applyingPromo = false;
            this.promoOnce = true;
            if (!promoInfo) {
                this.overview.finalSummaryList = [...this.overview.summaryList];
                this.promoApplied = false;
                return false;
            }

            this.overview.finalSummaryList = [
                ...this.overview.summaryList,
                [
                    this.$t('LABELS.promo-code-discount'),
                    `-${this.formatPrice(
                        promoInfo[this.$store.currency].amount_decimal
                    )} ${promoInfo[this.$store.currency].currency}`,
                ],
            ];

            this.promoInfo = promoInfo;
            this.promoApplied = true;
        },
        async handleWorkflow() {
            const workflows = localStorage.getItem('workflows')
                ? JSON.parse(localStorage.getItem('workflows'))
                : {};
            const currentWorkflowId = workflows[this.overview.cruiseCode]
                ? workflows[this.overview.cruiseCode]
                : this.$router.push({
                      path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                  });

            this.overview.workflowId = currentWorkflowId;
        },
        async loadPassengers() {
            const { workflow, cruise, passengers } =
                await this.api.getPassengers(this.overview.workflowId);
            this.workflow = workflow;

            if (!workflow || !cruise || !passengers) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error',
                });
            }

            if (
                passengers.length ===
                workflow.guest_count_adult + workflow.guest_count_child
            ) {
                this.passengersAdult = passengers.filter(
                    (p) => p.type === 'Adult'
                );
                this.passengersChild = passengers.filter(
                    (p) => p.type === 'Child'
                );
                return undefined;
            }

            const defaultNationality =
                location.host.split('.')[-1] === 'sk' ? 'sk' : 'cs';
            const defaultCountry =
                location.host.split('.')[-1] === 'sk' ? 'sk' : 'cz';

            for (
                let i = 0;
                i < workflow.guest_count_adult + workflow.guest_count_child;
                i++
            ) {
                const thisYear = new Date().getFullYear();
                const passengerType =
                    i < workflow.guest_count_adult ? 'Adult' : 'Child';
                const defaultBirthDate =
                    passengerType === 'Adult'
                        ? `${thisYear - 30}-01-01`
                        : `${thisYear - 10}-01-01`;

                const passenger = {
                    type: passengerType,
                    firstName: '',
                    lastName: '',
                    sex: 'NotAvailable',
                    nationality: defaultNationality,
                    countryCode: defaultCountry,
                    zip: '',
                    birthDate: defaultBirthDate,
                    email: '',
                    prefix: getPhonePrefix(),
                    phone: '',
                };

                if (passengerType === 'Adult') {
                    this.passengersAdult.push(passenger);
                } else if (passengerType === 'Child') {
                    this.passengersChild.push(passenger);
                }
            }

            Object.keys(cruise).map((key) => {
                if (key === 'days') {
                    this.overview.duration = cruise[key];
                } else if (key === 'summaryList') {
                    this.overview.summaryList = cruise[key];
                    this.overview.finalSummaryList = cruise[key];
                } else {
                    this.overview[camelCase(key)] = cruise[key];
                }
            });
        },
        async loadDials() {
            const dials = await this.api.getDials();
            const wantedDials = {
                sex: 'genderOptions',
                nationality: 'nationalityOptions',
                country: 'countryOptions',
            };

            Object.keys(wantedDials).forEach((dialName) => {
                const dial = dials[dialName];

                this[wantedDials[dialName]] = Object.keys(dial).map((key) => {
                    return {
                        code: key,
                        text: upperCaseFirst(dial[key]),
                    };
                });
            });
        },
        async makeReservation(preferredPayment, payWithCard = false) {
            const passengers = [
                ...this.passengersAdult,
                ...this.passengersChild,
            ];
            const singlePhonePassengers = passengers.map((pass) => {
                pass = { ...pass };
                pass.phone = pass.prefix + pass.phone;
                delete pass.prefix;
                return pass;
            });

            const validationStatus = this.validateForms();
            if (!validationStatus) {
                return false;
            }

            if (!this.$store.user.isLogged) {
                if (
                    !(await this.handleNotLoggedUser(
                        this.passengersAdult[0].email
                    ))
                ) {
                    return false;
                }
            }

            this.creatingReservation = true;
            await this.$store.user.update({
                zip: `${passengers[0].zip}`,
                country_code: passengers[0].countryCode,
            });
            await this.api.setPassengers(
                this.overview.workflowId,
                singlePhonePassengers,
                this.$store.user.details.id
            );

            const reqBody = {
                preferredPayment,
            };
            if (this.promoCode) {
                reqBody.promo = this.promoCode;
            }

            const resp = await this.api.createBooking(
                this.overview.workflowId,
                reqBody
            );

            this.creatingReservation = false;
            if (resp.data.error !== null) {
                window.scrollTo(0, 0);
                this.error.show = true;
                this.error.text = {
                    cs: [this.$t('ERRORS.cannot-create-reservation')],
                    sk: [this.$t('ERRORS.cannot-create-reservation')],
                };
                return false;
            }

            const query = { token: this.overview.workflowId };

            if (payWithCard && resp.data.order) {
                const { ticket_full, ticket_deposit } = resp.data.order;

                query.redirect =
                    preferredPayment === 'full' ? ticket_full : ticket_deposit;
            }

            this.$router.push({
                path: `/rezervace/${this.overview.cruiseCode}/dokoncena/`,
                query,
            });
        },
        validateForms() {
            const passengers = [
                ...this.passengersAdult,
                ...this.passengersChild,
            ];
            let failed = false;
            let hasAdult = false;

            this.closeNotification();
            passengers.forEach((passenger, i) => {
                const mainPassengerRequired = [
                    'firstName',
                    'lastName',
                    'sex',
                    'nationality',
                    'birthDate',
                    'email',
                    'prefix',
                    'phone',
                    'zip',
                    'countryCode',
                ];
                const passengerRequired = [
                    'firstName',
                    'lastName',
                    'sex',
                    'nationality',
                    'birthDate',
                ];

                const requiredFields =
                    i === 0 ? mainPassengerRequired : passengerRequired;

                requiredFields.forEach((reqInputName) => {
                    if (
                        passenger[reqInputName] === '' ||
                        passenger[reqInputName] === undefined
                    ) {
                        window.scrollTo(0, 0);
                        this.error.show = true;
                        this.error.text = {
                            cs: [this.$t('ERRORS.fill-required')],
                            sk: [this.$t('ERRORS.fill-required')],
                        };
                        failed = true;
                    }
                    if (
                        reqInputName === 'firstName' ||
                        reqInputName === 'lastName'
                    ) {
                        const regex = /^[a-zA-Z ]*$/;
                        if (!regex.test(passenger[reqInputName])) {
                            window.scrollTo(0, 0);
                            this.error.show = true;
                            this.error.text = {
                                cs: [this.$t('ERRORS.contains-diacritics')],
                                sk: [this.$t('ERRORS.contains-diacritics')],
                            };
                            failed = true;
                        }
                    }
                    if (reqInputName === 'phone') {
                        const phone = passenger[reqInputName];
                        if (phone.split(' ').length > 1 || phone.length != 9) {
                            window.scrollTo(0, 0);
                            this.error.show = true;
                            this.error.text = {
                                cs: [this.$t('ERRORS.wrong-phone-format')],
                                sk: [this.$t('ERRORS.wrong-phone-format')],
                            };
                            failed = true;
                        }
                    }
                    if (reqInputName === 'prefix') {
                        const prefix = passenger[reqInputName];
                        if (
                            prefix.split(' ').length > 1 ||
                            prefix.slice(0, 1) !== '+' ||
                            isNaN(Number(prefix.slice(1)))
                        ) {
                            window.scrollTo(0, 0);
                            this.error.show = true;
                            this.error.text = {
                                cs: [this.$t('ERRORS.wrong-phone-format')],
                                sk: [this.$t('ERRORS.wrong-phone-format')],
                            };
                            failed = true;
                        }
                    }
                    if (reqInputName === 'birthDate') {
                        const dateBrithTime = new Date(
                            passenger[reqInputName]
                        ).getTime();
                        const todayTime = new Date().getTime();

                        if (isNaN(dateBrithTime)) {
                            failed = true;
                        } else if (todayTime - dateBrithTime >= 568080000000) {
                            hasAdult = true;
                        }
                    }
                });
            });

            if (!hasAdult) {
                if (this.error.show === false) {
                    window.scrollTo(0, 0);
                    this.error.show = true;
                    this.error.text = {
                        cs: [this.$t('ERRORS.at-least-one-adult')],
                        sk: [this.$t('ERRORS.at-least-one-adult')],
                    };
                    failed = true;
                }
            }

            if (!this.allowGDPR) {
                if (this.error.show === false) {
                    window.scrollTo(0, 0);
                    this.error.show = true;
                    this.error.text = {
                        cs: [this.$t('ERRORS.agree-gdpr')],
                        sk: [this.$t('ERRORS.agree-gdpr')],
                    };
                    failed = true;
                }
            }

            return !failed;
        },
        closeNotification() {
            this.error.show = false;
            this.error.text = {
                cs: [],
                sk: [],
            };
        },
        validateReservation() {
            if (
                this.workflow.guest_count_adult === 0 ||
                !this.workflow.fare_code ||
                !this.workflow.cabin_number ||
                !this.workflow.category_code ||
                this.workflow.booking_number
            ) {
                this.$router.push({
                    path: `/rezervace/${this.overview.cruiseCode}/kajuta-a-ubytovaci-balicek/`,
                    hash: '#generic-error',
                });
            }

            const mandatoryFlight = this.workflow.components.find(
                (flight) =>
                    flight.type === 'Flight' &&
                    flight.direction === 'Both' &&
                    flight.mandatory
            );
            if (mandatoryFlight) {
                const savedFlight = this.workflow.flight;
                if (
                    !mandatoryFlight.cities.find(
                        (flight) => flight.code === savedFlight
                    )
                ) {
                    // There is mandatory flight and user didn't chose it
                    this.$router.push({
                        path: `/rezervace/${this.overview.cruiseCode}/doprava/`,
                        hash: '#not-chosen',
                    });
                }
            }
        },
        async handleNotLoggedUser(email) {
            const userData = {};

            userData.first_name = this.passengersAdult[0].firstName;
            userData.last_name = this.passengersAdult[0].lastName;
            userData.sex = this.passengersAdult[0].sex;
            userData.zip = `${this.passengersAdult[0].zip}`;
            userData.country_code = this.passengersAdult[0].countryCode;
            userData.nationality_code = this.passengersAdult[0].nationality;
            userData.birth_date = this.passengersAdult[0].birthDate;
            userData.phone =
                this.passengersAdult[0].prefix + this.passengersAdult[0].phone;

            const newAccountId = await this.api.tryRegisterAccount(
                email,
                userData
            );

            if (!newAccountId) {
                this.showLoginPopup = true;
                return false;
            }

            this.$store.user.details.id = newAccountId;
            return true;
        },
    },
    computed: {
        finalPrice() {
            const overallPrice = this.overview.fullPrice[this.$store.currency];
            if (this.promoApplied) {
                const promo = this.promoInfo?.[this.$store.currency];
                return {
                    amount_decimal:
                        overallPrice?.amount_decimal - promo?.amount_decimal,
                    amount_int: overallPrice?.amount_int - promo?.amount_int,
                    currency: overallPrice?.currency,
                    precision: overallPrice?.precision,
                };
            }
            return overallPrice;
        },
        isDepositAvailable() {
            if (this.workflow.fare_code === 'PIND') {
                return false;
            }
            const today = new Date();
            const tripDate = new Date(this.overview?.departureDate);
            const todayTrip = new Date(this.overview?.departureDate);
            todayTrip.setMonth(today.getMonth());
            todayTrip.setFullYear(today.getFullYear());
            todayTrip.setDate(today.getDate());
            return (
                tripDate.getTime() - todayTrip.getTime() >
                50 * 24 * 60 * 60 * 1000
            );
        },
    },
    async mounted() {
        this.isLoading = true;
        await this.handleWorkflow();
        await this.loadDials();
        await this.loadPassengers();
        this.isLoading = false;
        this.validateReservation();
    },
    components: {
        Footer,
        MainNav,
        ReservationNav,
        ReservationSide,
        vSelect,
        Notification,
        Loading,
        LoginPage,
        Head,
    },
};
</script>
